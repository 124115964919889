import {Component, Inject, OnInit} from '@angular/core';
import {ISubscription} from '../../../../types/subscription';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select} from '@ngrx/store';


@Component({
  selector: 'app-transfer-license-dialog',
  templateUrl: './transfer-license-dialog.component.html',
  styleUrls: ['./transfer-license-dialog.component.scss']
})
export class TransferLicenseDialogComponent implements OnInit {

  constructor(
    private readonly dialogRef: MatDialogRef<TransferLicenseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: {subscriptions: ISubscription[]}
  ) {}

  ngOnInit(): void {
  }

  onSubmit(subscription: ISubscription) {
    this.dialogRef.close(subscription);
  }

  onCancel() {
    this.dialogRef.close();
  }

  protected readonly select = select;
}
