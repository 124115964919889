<div class="folder-wrapper">
    <div class="ransomware-description">
        <div style="margin-top: 0;">
            {{'policy.ransomware.description' | translate}}
        </div>
        <div style="margin-top: 1px;margin-left: 25px;">
          <ol>
            <li>{{'policy.ransomware.description-item1' | translate}}</li>
            <li>{{'policy.ransomware.description-item2' | translate}}</li>
          </ol>
        </div>
        <div style="margin-top: 1px;">
            {{'policy.ransomware.description2' | translate}}
        </div>
    </div>

    <form id="registerForm" [formGroup]="extFormGroup">
    <div class="ext-section">
        <div class="section-title">
            <h2>{{ 'policy.ransomware.default' | translate }}</h2>
        </div>
        <div class="buttons">
            <button id="deselectalldefault" mat-stroked-button color="primary" class="button" (click)="deselectAll()"
                aria-label="Deselect All Default Ransomware Extensions.">
                {{'policy.deselectall' | translate}}
            </button>
            <button id="selectalldefault" mat-stroked-button color="primary" class="button" (click)="selectAll()"
                aria-label="Select All Default Ransomware Extensions.">
                {{'policy.selectall' | translate}}
            </button>
        </div>
    </div>

    <div>
        <div class="ext-list-section">
            <div class="box">
                <div class="extension" *ngFor="let extension of extensions">
                    <mat-checkbox (change)="extChanged(extension)" [formControlName]="extension.extension"
                                  id="select-{{extension.extension}}">{{extension.extension}}</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    </form>

    <form id="customForm" [formGroup]="customExtFormGroup">
        <div class="custom-ext-section">
            <div class="section-title">
              <div class="add-extension-btn">
                <button [disableRipple]="true" mat-raised-button color="primary" (click)="onAddExtensionClick()">
                    <span class="mdi mdi-plus-box-outline icon"></span>
                    <span class="add-button-label">{{'policy.ransomware.custom' | translate}}</span>
                </button>
              </div>
            </div>
            <div class="custom-ext-row" autocomplete="off" id="custom-ext-{{i}}" *ngFor="let control of customControls; let i = index;">
                <mat-form-field floatLabel="never" class="custom-ext-form-field" appearance="outline">
                    <input matInput type="text" autocomplete="off" id="text-ext-{{i}}" class="ext-label input-edit-mode" #extensionInputs
                        appDebounceInput
                        [debounceTime]="250"
                        [ngClass]="{'input-error': hasExtensionError(i)}"
                        (focusin)="onFocusIn($event, i)"
                        (focusout)="onFocusOut($event, i)"
                        (debounceInput)="onInputChange($event, i)"
                        formControlName="{{i}}"
                        placeholder="{{'FILE_EXTENSION' | translate}}"/>
                </mat-form-field>
                <span class="remove-row-icon">
                    <button [disableRipple]="true" mat-icon-button (click)="removeExtensionRow(i)"
                            matTooltip="{{'REMOVE_FILE_EXT' | translate}}" matTooltipPosition="right"
                            attr.aria-label="{{'REMOVE_FILE_EXT' | translate}}.">
                        <span class="mdi mdi-close-circle-outline"></span>
                    </button>
                </span>
                <div *ngIf="hasExtensionError(i)" style="margin: -18px 0 0 0;color: #c90000;font-size: 12px;">{{extensionErrors[i]}}</div>

            </div>
        </div>
    </form>
</div>
