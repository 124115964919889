
<div class="subscription-container">
    <div class="subscription-description">
        <div>
            {{'L_PRODUCT' | translate}}: {{subscription.stripe_product.name}}
            <span *ngIf="subscription.type === LicenseType.LICENSE_TYPE_TRIAL"> - {{'subscriptions.trial' | translate}}</span>
        </div>
        <div>
            {{'ADM_SUB_ID' | translate}}: {{subscription.subscription_id}}
        </div>
        <div>
            {{'L_OWNER' | translate}}: <span *ngIf="!!subscription.company">{{subscription.company}}, </span>{{subscription.email}}
        </div>
    </div>
    <div class="card-container">
        <mat-card appearance="raised">
            <mat-card-header>
                <mat-card-title>
                    {{'L_AVAILABLE' | translate}}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="card-detail">{{countToString(subscription.count - subscription.used)}}</div>
                <div>{{'subscriptions.used-of-total' | translate : {"used": subscription.used, "total": countToString(subscription.count)} }}</div>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="raised" [ngClass]="isExpired() ? 'expired' : ''" class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title>
                    <span *ngIf="isExpired(); else expires">{{'EXPIRED' | translate}}</span>
                    <ng-template #expires>
                        <span>{{'EXPIRES' | translate}}</span>
                    </ng-template>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="card-detail">{{subscription.exp_timestamp | date}}</div>
                <div>{{subscription.exp_timestamp | expiresIn : 'long'}}</div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
