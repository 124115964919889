import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    IClientPolicyDefaults,
    ID3EPolicy,
    IPolicyExtension,
    IPolicySection, IRansomExtensions
} from '../../../../types/d3ePolicy';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ALPHA_NUMERIC_REGEXP, NO_DOT_EXE_OR_DLL_REGEXP } from '../../../../types/common';

@Component({
  selector: 'app-ransomware-file-types',
  templateUrl: './ransomware-file-types.component.html',
  styleUrls: ['./ransomware-file-types.component.scss']
})
export class RansomwareFileTypesComponent implements OnInit, OnChanges {
  @Input()d3ePolicy: ID3EPolicy;
  @Input()clientPolicyDefaults: IClientPolicyDefaults;
  @Output()ransomExtErrorStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()ransomExtensionsChanged: EventEmitter<IRansomExtensions> = new EventEmitter<IRansomExtensions>();

  ransomwareDefaults: IPolicySection = null;
  extensions: IPolicyExtension[] = [];
  extFormGroup: UntypedFormGroup;
  customExtFormGroup: UntypedFormGroup;
  customExts: string[] = [];
  extensionErrors: string[] = [];
  customControls: AbstractControl[] = [];

  constructor(private translate: TranslateService,
              private formBuilder: UntypedFormBuilder
  ) {
  }


  ngOnInit(): void {
    this.ransomwareDefaults = this.clientPolicyDefaults.sections.find(s => s.name.toLocaleLowerCase() === 'ransomware file types');
    this.extensions = this.ransomwareDefaults.groups[0].extensions;
    function compareExtensions(a: IPolicyExtension, b: IPolicyExtension) {
        if (a == null && b == null) {
          return 0;
        }
        if ( a == null ) {
          return -1;
        }
        if ( b == null ) {
          return 1;
        }
        return a.extension.localeCompare(b.extension);
    }
    this.extensions = this.extensions.sort(compareExtensions);
    this.extFormGroup = this.formBuilder.group({});
    this.customExtFormGroup = this.formBuilder.group( {} );

    this.setExtFromPolicy(this.d3ePolicy);
  }

  private setExtFromPolicy(_d3ePolicy) {
    // first remove all custom extensions!
    let ransomExts = null;
    if ( _d3ePolicy.ransomExts === undefined || _d3ePolicy.ransomExts == null ) {
        ransomExts = [];
        this.extensions.forEach(extension => {
          ransomExts.push(extension.extension);
      });
    } else {
        ransomExts = [..._d3ePolicy.ransomExts];
    }
    this.extensions.forEach(extension => {
        let value = false;
        if (ransomExts === undefined || _d3ePolicy == null) {
            value = true;
        } else if (ransomExts.includes(extension.extension)) {
            const index = ransomExts.indexOf(extension.extension);
            if (index !== -1) {
              ransomExts.splice(index, 1);
            }
            value = true;  // this extension is in the policy
        }
        if ( this.extFormGroup.controls[extension.extension] ) {
          this.extFormGroup.controls[extension.extension].setValue(value);
        } else {
            this.extFormGroup.addControl(extension.extension, new UntypedFormControl(value));
        }
    });
    this.customExts = [];
    this.customExtFormGroup = this.formBuilder.group( {} );
    this.customControls = [];
    ransomExts.forEach( customExt => {
        this.addCustomExtToForm(customExt);
    });
    this.addCustomExtToForm('');

    // still need to determine NOT default are in the list
  }

    private addCustomExtToForm(customExt) {
        this.customExtFormGroup.addControl(String(this.customExts.length), new UntypedFormControl(customExt));
        this.customExts.push(customExt);
        this.extensionErrors.push(null);
        const index = this.customExts.length - 1;
        this.customControls.push(this.customExtFormGroup.controls[index.toString()]);
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.d3ePolicy && changes.d3ePolicy.currentValue) {
        this.setExtFromPolicy(changes.d3ePolicy.currentValue);
      }
  }

  deselectAll() {
      this.extensions.forEach( extension => {
          this.extFormGroup.controls[extension.extension].setValue(false);
      });
      this.changeSelectedExtensions();
  }

  selectAll() {
      this.extensions.forEach( extension => {
          this.extFormGroup.controls[extension.extension].setValue(true);
      });
      this.changeSelectedExtensions();
  }

  onAddExtensionClick() {
    this.addCustomExtToForm('');
  }

  extChanged(extension) {
      this.changeSelectedExtensions();
  }

  onInputChange(event, index) {
      // TODO: uncomment
    const newValue = this.customExtFormGroup.controls[index.toString()].value;
    this.customExts[index] = newValue;
    this.validateExtensions(newValue, index);
    this.changeSelectedExtensions();
  }

  removeExtensionRow(index: number) {
      // TODO: uncomment
      this.customExts.splice(index, 1);
      this.extensionErrors.splice(index, 1);
      this.customExtFormGroup.removeControl(index.toString());
      this.customControls.splice(index, 1);
      this.changeSelectedExtensions();
  }

  hasExtensionError(i) {
    let hasError = false;
    if (this.extensionErrors[i] !== null) {
      hasError = true;
    }
    return hasError;
  }

  onFocusIn(event, index) {
    const value = this.customExtFormGroup.controls[String(index)].value || '';
    this.validateExtensions(value, index);
  }

  onFocusOut(event, index) {
    const value = this.customExtFormGroup.controls[String(index)].value || '';
    this.validateExtensions(value, index);
  }

  validateExtensions(customExt, index: number) {
    let error = null;
    const extension = customExt.trim();

    if (NO_DOT_EXE_OR_DLL_REGEXP.test(extension) ) {
      error = this.translate.instant('ERROR_INVALID_EXT');
    }

    if (!(this.customExts.length === 1 && extension === '') && !ALPHA_NUMERIC_REGEXP.test(extension) ) {
      error = this.translate.instant('ERROR_ONLY_ALPHA');
    }
    //
    if (this.customExts.length > index) {
        this.extensions.forEach((c, i) => {
            if (c.extension.toLocaleLowerCase() === extension.toLocaleLowerCase()) {
              error = this.translate.instant('policy.ransomware.defaultexists');
            }
        });
        this.customExts.forEach((c, i) => {
            if (c.toLocaleLowerCase() === extension.toLocaleLowerCase() && i !== index) {
                error = this.translate.instant('ERROR_EXT_EXISTS');
            }
        });
    }

    if ((extension.length < 1 || extension.length > 260) && !(this.customExts.length === 1 && extension === '')) {
      error = this.translate.instant('ERROR_EXT_LENGTH');
    }

    this.extensionErrors[index] = error;
    const hasError = !this.extensionErrors.every(c => c === null);
    this.ransomExtErrorStateChanged.emit(hasError);

  }

  private changeSelectedExtensions() {
    const selectedExts: IRansomExtensions = new class implements IRansomExtensions {
        ransomExtensions: string[] = [];
    };

    // tslint:disable-next-line:forin
    for (const fieldName in this.extFormGroup.controls) { // 'field' is a string
        const control = this.extFormGroup.get(fieldName); // 'control' is a FormControl
        if (control.value) {
            selectedExts.ransomExtensions.push(fieldName);
        }
    }

    this.customExts.forEach( ext => {
        if ( ext.length > 0 ) {
            selectedExts.ransomExtensions.push(ext);
        }
    });

    this.ransomExtensionsChanged.emit(selectedExts);
  }

}
