import {IGroup} from '../../../types/group';
import {createReducer, on} from '@ngrx/store';
import {organizationsLoadedSuccess} from './organizations.actions';

export const initialState: IGroup[] = [];

export const organizationsReducer = createReducer(
  initialState,
  on(organizationsLoadedSuccess, (state, {organizations}) => organizations),
);
