import { Injectable } from '@angular/core';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {API_URL} from '../shared/api';
import {map} from 'rxjs/operators';

export interface SearchResultEntry {
  id: number | string;  // Could be a UUID or DB auto-assigned numeric unique ID
  name: string;
  groupId: number;
}

export interface SearchResults {
  endpoints: SearchResultEntry[];
  endpointGroups: SearchResultEntry[];
  userGroups: SearchResultEntry[];
  policies: SearchResultEntry[];
  users: SearchResultEntry[];
  organizations: SearchResultEntry[];
}

export interface SearchResultResponse {
  data: SearchResults;
}

export interface RecentResultEntry {
  id: number | string;
  name: string;
  groupId: number;
  dttm: Date;
}

export interface RecentResults {
  activations: RecentResultEntry[];
  users: RecentResultEntry[];
}

export interface RecentResultResponse {
  data: RecentResults;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {
  getSearchResults(term: string, limit: number): Observable<SearchResults> {
    const query = [
      ['q', term],
      ['limit', limit.toString()],
    ];
    const url = this.getUrl(API_URL.GLOBAL_SEARCH, query);
    return this.http.get<SearchResultResponse>(url).pipe(
      map(response => response.data)
    );
  }

  getRecentResults(): Observable<RecentResults> {
    const url = this.getUrl(API_URL.RECENT_SEARCH);
    return this.http.get<RecentResultResponse>(url).pipe(
      map(response => response.data)
    );
  }
}
