import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {IPbaUser} from '../../../../../types/d3ePolicy';
import {PbaPasswordRequirementsComponent} from '../../pba-password-requirements/pba-password-requirements.component';

const PBA_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~! @#$^&*()_\-+=[\]:<>.]).{8,}$/;

export interface IPbaUserDialogOptions {
  existingUsers: IPbaUser[];
  pbaUser?: IPbaUser;
}

@Component({
  selector: 'app-pba-user-dialog',
  templateUrl: './pba-user-dialog.component.html',
  styleUrl: './pba-user-dialog.component.scss'
})
export class PbaUserDialogComponent {
  form: FormGroup;

  constructor(public readonly dialogRef: MatDialogRef<PbaUserDialogComponent>,
              private readonly dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public options: IPbaUserDialogOptions | null) {

    const pbaUniqueUsername: ValidatorFn = (control: AbstractControl): ValidationErrors  => {
      const existingUsernames = options?.existingUsers?.filter(u => u != options?.pbaUser).map(u => u.username);
      if (!!existingUsernames && existingUsernames.indexOf(control.value) != -1) {
        return {usernameExists: true};
      }
      return null;
    };

    const pbaPasswordsMatch: ValidatorFn = (control: AbstractControl): ValidationErrors  => {
      if (control.value.password != control.value.confirm) {
        return {passwordsMatch: true};
      }
      return null;
    };

    const pbaPasswordEmptyOrCompliant: ValidatorFn = (control: AbstractControl): ValidationErrors  => {
      if (control.value == '') {
        return null;
      }
      return (PBA_PASSWORD_REGEX.test(control?.value)) ? null : {passwordValid: true};
    };

    let passwordValidators: ValidatorFn[] = [];

    if (!options?.pbaUser) {  // New user rather than existing user; password required
      passwordValidators = [
        Validators.required,
        Validators.maxLength(128),
        Validators.pattern(PBA_PASSWORD_REGEX),
      ]
    } else {
      passwordValidators = [
        pbaPasswordEmptyOrCompliant,
        Validators.maxLength(128),
      ]
    }

    this.form = new FormGroup({
      username: new FormControl({
        value: !!options?.pbaUser ? options.pbaUser.username : '',
        disabled: !!options?.pbaUser ? !!options.pbaUser.id : false,
      }, {
        validators: [Validators.required, pbaUniqueUsername],
      }),
      email: new FormControl(!!options?.pbaUser ? options?.pbaUser.email : ''),
      password: new FormControl(options?.pbaUser?.password || '', {validators: passwordValidators}),
      confirm: new FormControl(options?.pbaUser?.password || '', {validators: passwordValidators}),
      administrator: new FormControl(!!options?.pbaUser ? options?.pbaUser.administrator : false, Validators.required),
    }, {
      validators: [pbaPasswordsMatch],
      updateOn: 'change',
    });  // TODO: Add form-level validator to check password == confirm and for username uniqueness
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    const pbaUser: IPbaUser = {
      id: this.options?.pbaUser?.id || undefined,
      username: this.form.controls.username.getRawValue(),
      email: this.form.value.email,
      password: this.form.value.password,
      administrator: this.form.value.administrator,
    };
    this.dialogRef.close(pbaUser);
  }

  passwordRequirements() {
    this.dialog.open(PbaPasswordRequirementsComponent);
  }
}
