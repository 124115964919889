import {Component, Input, OnInit} from '@angular/core';
import {IUser} from '../../../../../types/user';

@Component({
  selector: 'app-orgs-overview-user',
  templateUrl: './orgs-overview-user.component.html',
  styleUrls: ['./orgs-overview-user.component.scss']
})
export class OrgsOverviewUserComponent implements OnInit {

  @Input() user: IUser;

  constructor() { }

  ngOnInit(): void {
  }

}
