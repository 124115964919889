import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pba-password-requirements',
  templateUrl: './pba-password-requirements.component.html',
  styleUrl: './pba-password-requirements.component.scss'
})
export class PbaPasswordRequirementsComponent {

  constructor(public readonly dialogRef: MatDialogRef<PbaPasswordRequirementsComponent>) {
  }

  okayClicked() {
    this.dialogRef.close();
  }
}
