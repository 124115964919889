import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SearchResults, SearchService} from '../../../../../services/search.service';
import {Observable} from 'rxjs';
import {AppState} from '../../../../store/app.state';
import {Store} from '@ngrx/store';
import {selectUser} from '../../../../store/user/user.selectors';
import {IManageUser, UserRole} from '../../../../../types/user';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnChanges {
  user$: Observable<IManageUser> = this.store.select(selectUser())

  @Input() term: string;
  @Input() limit: number = 10;

  results$: Observable<SearchResults>;

  constructor(private readonly searchService: SearchService,
              private readonly store: Store<AppState>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.results$ = this.searchService.getSearchResults(this.term, this.limit);
  }

  protected readonly UserRole = UserRole;
}
