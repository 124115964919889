
export const MINUTE = 60
export const HOUR = MINUTE * 60;
export const DAY = 24 * HOUR;
export const WEEK = DAY * 7;

export class DashboardUtils {

    static getTimeNow() {
        let now = new Date().getTime();
        return now;
    }

    static getTime(daysAgo: number = 0) {
       const offset = (DAY * daysAgo) * 1000
       const time_now = this.getTimeNow() - offset;
       return time_now - offset;
    }

    static getSeconds(daysAgo: number = 0) {
        return Math.floor(this.getTime(daysAgo) / 1000);
    }
    static  getWhen(seconds: number, daysAgo: string, hoursAgo: string, minsAgo: string, secsAgo: string): string {
      if ( Math.floor(seconds / DAY) > 0 ) {
            return Math.floor(seconds / DAY) + ' ' + daysAgo;
      }
      if ( Math.floor(seconds / HOUR) > 0 ) {
            return Math.floor(seconds / HOUR) + ' ' + hoursAgo;
      }
      if ( Math.floor(seconds / MINUTE) > 0 ) {
            return Math.floor(seconds / MINUTE) + ' ' + minsAgo;
      }
      return Math.floor(seconds) + ' ' + secsAgo;
    }
}
