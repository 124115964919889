import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserService} from '../../../services/user-service';
import {LOAD_ORGANIZATIONS, ORGANIZATIONS_LOADED_SUCCESS} from './organizations.actions';
import {exhaustMap, map} from 'rxjs/operators';

@Injectable()
export class OrganizationsEffects {
  loadOrganizations$ = createEffect(() => this.actions$.pipe(
    ofType(LOAD_ORGANIZATIONS),
    exhaustMap(() => this.userService.api_get_organizations().pipe(
      map(organizations =>({type: ORGANIZATIONS_LOADED_SUCCESS, organizations: organizations}))
    ))
  ))

  constructor(private readonly actions$: Actions,
              private readonly userService: UserService) {
  }
}