<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            {{'policy.pba.pba-users.title' | translate}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <div class="button-container">
            <button mat-raised-button color="primary" (click)="addPbaUser()">
                {{'G_ADD' | translate}}
            </button>
            <button mat-raised-button color="primary" (click)="deleteSelected()" [disabled]="selection.selected.length == 0">
                {{'G_REMOVE' | translate}}
            </button>
        </div>

        <mat-table [dataSource]="pbaUsers">

            <ng-container matColumnDef="select">
                <mat-header-cell scope="col" *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  aria-label="User Selection Checkbox"
                                  color='primary'>
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let pbaUser">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="checkboxToggle($event, pbaUser)"
                                  [checked]="selection.isSelected(pbaUser)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="username">
                <mat-header-cell *matHeaderCellDef>{{'ADM_UNAME' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let pbaUser let i=index">{{pbaUser.username}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>{{'ADM_EMAIL' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let pbaUser">{{pbaUser.email}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="administrator">
                <mat-header-cell *matHeaderCellDef>{{'U_ROLE' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let pbaUser">
                    @if (pbaUser.administrator) {
                        {{'APU_ADMIN' | translate}}
                    } @else {
                        {{'APU_USER' | translate}}
                    }
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let user, let i = index; columns: displayedColumns" (click)="editPbaUser(i)"></mat-row>
        </mat-table>
    </mat-card-content>
</mat-card>
