import {Component, Inject} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-generate-confirmation-dialog',
  templateUrl: './generate-confirmation-dialog.component.html',
  styleUrl: './generate-confirmation-dialog.component.scss'
})
export class GenerateConfirmationDialogComponent {
  formGroup: FormGroup;
  how_many: number;

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly dialogRef: MatDialogRef<GenerateConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.how_many = data.how_many;
    this.formGroup = this.formBuilder.group({
      maintEnd: [data.maintEnd, Validators.required]
    });
  }

  onSaveClick() {
    this.dialogRef.close(this.formGroup.value.maintEnd);
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
