import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../services/user-service';
import {BehaviorSubject} from 'rxjs';
import {IOrgTreeNode} from '../../../../types/org-tree';


@Component({
  selector: 'app-orgs-overview',
  templateUrl: './orgs-overview.component.html',
  styleUrls: ['./orgs-overview.component.scss']
})
export class OrgsOverviewComponent implements OnInit {
  tree$: BehaviorSubject<IOrgTreeNode[]> = new BehaviorSubject([]);

  constructor(private readonly userService: UserService) {
    userService.api_get_org_tree().subscribe(response => this.tree$.next(response.tree));
  }

  ngOnInit(): void {
  }

}
