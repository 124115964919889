import { Injectable } from '@angular/core';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';

export interface IBitLockerCompliance {
  compliant: number;
  nonCompliant: number;
  total: number;
  errors: number;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  getBitLockerCompliance(selectedOrgId: string): Observable<IBitLockerCompliance> {
    let query: string[][] = [];
    if (!!selectedOrgId) {
      query.push(['selectedOrgId', selectedOrgId]);
    }
    const url = this.getUrl('/api/v1/bitlocker-compliance', query);
    return this.http.get<IBitLockerCompliance>(url)
  }
}
