import {Component, Input} from '@angular/core';

type NgClasses = {[className: string]: boolean | null};

// These class names need to be defined in the theme somewhere
const getNgClasses = (enabled: boolean): NgClasses  => {
  return {
    "sensor-active-icon": enabled,
    "sensor-inactive-icon": !enabled,
  }
}

@Component({
  selector: 'app-title-status',
  templateUrl: './title-status.component.html',
  styleUrls: ['./title-status.component.scss']
})
export class TitleStatusComponent {
  @Input() enabled: boolean;

  get ngClasses() {
    return getNgClasses(this.enabled)
  }
}
