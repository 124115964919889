import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {IGroup} from '../../../types/group';

export const selectOrganizationsState = (state: AppState) => state.organizations;

export const selectOrganizations = () => createSelector(
  selectOrganizationsState,
  (organizations: IGroup[]) => organizations
);
