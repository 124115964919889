<div class="details-container">
    @if (loaded$ | async) {
        <form [formGroup]="form">
            <div class="form-container">
                <div class="form-element">
                    <mat-form-field appearance="outline" class="group-form-field">
                        <mat-label>{{ 'groups.forms.group-name' | translate }}</mat-label>
                        <input matInput formControlName="name" (change)="emitChanges()"/>
                        <mat-error *ngIf="form.controls.name?.errors?.exists">{{ 'groups.name-exists' | translate }}</mat-error>
                        <mat-error *ngIf="form.controls.name?.errors?.required">{{ 'groups.name-required' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-element">
                    <mat-form-field appearance="outline" class="group-form-field">
                        <mat-label>{{ 'groups.forms.group-description' | translate }}</mat-label>
                        <input matInput formControlName="description" (change)="emitChanges()"/>
                    </mat-form-field>
                </div>
                <div class="form-element">
                    <mat-form-field appearance="outline" class="group-form-field">
                        <mat-label>{{ 'GA_POLICY' | translate }}</mat-label>
                        <mat-select formControlName="policy" (selectionChange)="emitChanges()">
                            <mat-option *ngFor="let policy of policies" [value]="policy.id">{{ policy.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    } @else {
        <ngx-skeleton-loader appearance="line" [theme]="formSkeletonTheme" count="3"></ngx-skeleton-loader>
    }
    <hr />
    <div class="table-container">
        <div class="device-table-header table-header">
            <div class="search-input-wrapper">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DSS_SEARCH' | translate }}</mat-label>
                    <input matInput [(ngModel)]="search" appDebounceInput (debounceInput)="getDevices()"
                           [debounceTime]="250" type="search">
                </mat-form-field>
            </div>
            <!--            <div class="column-chooser-wrapper">-->
            <!--                <app-column-chooser></app-column-chooser>-->
            <!--            </div>-->
        </div>
        <div class="table-wrapper">
            <table mat-table [dataSource]="dataSource" matSortActive="name" matSortDirection="asc"
                   class="mat-elevation-z0" matSort>

                <ng-container matColumnDef="select">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header class="checkbox-cell">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="toggleAll()"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        &nbsp;&nbsp;{{ countDisplay }}
                    </th>
                    <td mat-cell *matCellDef="let device" class="checkbox-cell">
                        <span [matTooltipDisabled]="device.device_set_id === groupSettings.groupId || device.device_set_id === -1"
                              matTooltip="Endpoint assigned to another group" matTooltipPosition="right">
                          <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="toggleRow($event.checked, device)"
                                        [checked]="selection.isSelected(device)"
                                        [disabled]="!selection.isSelected(device) && device.device_set_id !== groupSettings.groupId && device.device_set_id !== -1">
                          </mat-checkbox>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'NAME' | translate }}</th>
                    <td mat-cell *matCellDef="let device">
                        <span [matTooltipDisabled]="device.device_set_id === groupSettings.groupId || device.device_set_id === -1"
                              matTooltip="Endpoint assigned to another group" matTooltipPosition="right">
                            @if (device.deactivated == 0) {
                                <span>
                                  <a [routerLink]="['/endpoints']" [queryParams]="{endpoints_target:'inventory'}">
                                    {{ device.computer_name || '...' }}
                                  </a>
                                </span>
                            } @else {
                                <span class="deactivated-device-name">
                                    <span>
                                      <a [routerLink]="['/endpoints']" [queryParams]="{endpoints_target:'inventory'}">
                                        {{ device.computer_name || '...' }}
                                      </a>
                                    </span>
                                    <span>({{ 'DEACTIVATED' | translate }})</span>
                                </span>
                            }
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="os">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'END_OS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let device"> {{ device.os }}</td>
                </ng-container>

                <ng-container matColumnDef="last_logged_in_user">
                    <th scope="col" mat-header-cell *matHeaderCellDef
                        mat-sort-header>{{ 'END_LAST_LOGGED_IN' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let device"> {{ device.last_logged_in_user?.email }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"
                    [ngClass]="{'excluded-row': row.device_set_id !== groupSettings.groupId && row.device_set_id !== -1}">
                </tr>

            </table>

            @if (!(loaded$ | async)) {
                <ngx-skeleton-loader appearance="line" [theme]="tableSkeletonTheme" count="6"></ngx-skeleton-loader>
            }

        </div>
    </div>
</div>
