import {Component, OnInit} from '@angular/core';
import {RecentResults, SearchService} from '../../../../../services/search.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-search-recent',
  templateUrl: './search-recent.component.html',
  styleUrls: ['./search-recent.component.scss']
})
export class SearchRecentComponent implements OnInit {
  results$: Observable<RecentResults>;

  constructor(private readonly searchService: SearchService) {
  }

  ngOnInit() {
    this.results$ = this.searchService.getRecentResults();
  }
}
