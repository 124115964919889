import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-upload-or-enter-code-dialog',
  templateUrl: './upload-or-enter-code-dialog.component.html',
  styleUrls: ['./upload-or-enter-code-dialog.component.scss']
})
export class UploadOrEnterCodeDialogComponent {
  form: UntypedFormGroup;
  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly dialogRef: MatDialogRef<UploadOrEnterCodeDialogComponent>) {
    this.form = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }

  addCode(code: string) {

  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close(this.form.value.code);
  }

  onUploadClick(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      this.form.controls['code'].setValue(reader.result);
    };
    reader.readAsText(file)
  }
}
