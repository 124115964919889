import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseWidget} from 'gridstack/dist/angular';
import {HeaderService} from '../../../header.service';
import {EventsService} from '../../../../services/events-service';
import {TranslateService} from '@ngx-translate/core';
import {catchError} from 'rxjs/operators';
import {Observable, of, Subscription, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SelectedOrgMethod} from '../../../../shared/selectedOrg';
import {IEventSearchRequest, SEVERITY} from '../../../../types/events';
import {DashboardUtils} from '../dashboard-util';

enum ActivityType {
  Device = 0,
  User = 1
}

interface ActivityData {
  type: ActivityType;
  text: string;
  when: string; // of seconds ago
  uuid: string | null;
}

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-endpoint-activity',
  templateUrl: './endpoint-activity.component.html',
  styleUrls: ['./endpoint-activity.component.scss', '../dynamic-dashboard.common.scss']
})
export class EndpointActivityComponent extends BaseWidget implements OnInit, OnDestroy {
  @Input() selectedOrgId: string;

  protected readonly ActivityType = ActivityType;

  sortAttrs = [{'sortAttr': 'timestamp', 'sortOrder': 'desc'}];
  queryPageStart = 0;
  queryPageSize = 300;

  selectedEventTypes: string[] = [];

  loadingEvents = true;
  firstLoad = true;

  activityData: ActivityData[] = [];

  columnsToDisplay: string[] = [];

  timerTrigger$: Observable<number> = timer(0, 60000);
  refreshSub: Subscription;

  activityMetadata = {};

  daysAgo = 'Days Ago';
  hoursAgo = 'Hours Ago';
  minsAgo = 'Minutes Ago';
  secsAgo = 'Seconds Ago';

  allActivities = -1;
  selectedActivityFilter: -1 | ActivityType.User | ActivityType.Device = this.allActivities;

  constructor(private headerService: HeaderService,
              private eventsService: EventsService,
              private translate: TranslateService) {
    super();

    this.translate.get(['dashboard.ago_days', 'dashboard.ago_hours',
      'dashboard.ago_minutes', 'dashboard.ago_seconds'])
      .subscribe(vals => {
        this.daysAgo = vals['dashboard.ago_days'];
        this.hoursAgo = vals['dashboard.ago_hours'];
        this.minsAgo = vals['dashboard.ago_minutes'];
        this.secsAgo = vals['dashboard.ago_seconds'];
      });

    this.activityMetadata =
      {
        'd3e_activation': {type: ActivityType.Device, translation: 'dashboard.endpoint-activity.d3e_activation', detail: 'computer_name'},
        // 'endpoint_updated': {type: ActivityType.Device, translation: 'dashboard.endpoint-activity.endpoint_updated'},
        'downloaded_exe': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.downloaded_exe'},
        'downloaded_cli': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.downloaded_cli'},
        'downloaded_pba': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.downloaded_pba'},
        'password_reset': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.password_reset'},
        'user_login': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.user_login', data: true},
        'device_connected': {type: ActivityType.Device, translation: 'dashboard.endpoint-activity.device_connected', data: true},
        'device_disconnected': {type: ActivityType.Device, translation: 'dashboard.endpoint-activity.device_disconnected', data: true},
        'create_device_set': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.create_device_set', data: true},
        'modify_device_set': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.modify_device_set', data: true},
        'delete_device_set': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.delete_device_set', data: true},
        'create_policy': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.create_policy', data: true},
        'updated_policy': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.updated_policy', data: true},
        'deleted_policy': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.deleted_policy', data: true},
        'forced_policy_reset': {type: ActivityType.User, translation: 'dashboard.endpoint-activity.forced_policy_reset', data: true},
      };
    // file decryption events, profile and group events. say console login or d3e (and from which device)
    this.selectedEventTypes = Object.keys(this.activityMetadata);
    this.columnsToDisplay = ['icon', 'text', 'when'];

    this.headerService.orgSelectedChanged
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedOrgId = value;
        this.startTimer();
      });
  }

  ngOnInit() {
    //this.getActivity();
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.refreshSub.unsubscribe();
  }

  startTimer() {
    this.loadingEvents = true;
    this.activityData = [];
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
    this.refreshSub = this.timerTrigger$.subscribe(() => {
      this.getActivity();
    });
  }

  buildQuery(): IEventSearchRequest {
    const validActivityTypes = Object.keys(ActivityType).filter(k => !isNaN(Number(k)));
    if (this.selectedActivityFilter in validActivityTypes) {
      this.selectedEventTypes = Object.keys(this.activityMetadata)
        .filter(k => this.activityMetadata[k].type === this.selectedActivityFilter);
    } else {
      this.selectedEventTypes = Object.keys(this.activityMetadata);
    }

    const selectedOrg = SelectedOrgMethod.getSelectedOrg(this.selectedOrgId);
    let filterTime = DashboardUtils.getSeconds(14);
    return {
      search: null,
      severity: SEVERITY.INFO,
      sources: null,
      timeHigh: null,
      timeLow: filterTime,
      pageStart: this.queryPageStart,
      pageSize: this.queryPageSize,
      event_types: this.selectedEventTypes,
      sorts: this.sortAttrs,
      selectedOrgId: selectedOrg,
      translate: false
    };
  }

  getActivity() {
    this.loadingEvents = true;
    const query = this.buildQuery();
    this.eventsService.searchEvents(query)
      .pipe(
        catchError(() => of([])),
        untilDestroyed(this))
      .subscribe(
        eventResponse => {
          const secondsNow = DashboardUtils.getSeconds();

          this.firstLoad = false;
          const eventsList = eventResponse['events'];
          const itemList: ActivityData[] = [];
          const respQuery = eventResponse['query'];

          if (query.selectedOrgId == respQuery.selectedOrgId) {
            eventsList.forEach(event => {
              let eventType = event.type;
              let metaData = this.activityMetadata[eventType];
              if (metaData) {
                const type = metaData.type;
                const {text, uuid} = this.translateEvent(event, metaData);
                const seconds = secondsNow - event.timestamp;
                const when = DashboardUtils.getWhen(seconds, this.daysAgo, this.hoursAgo, this.minsAgo, this.secsAgo);
                let item: ActivityData = {
                  type: type,
                  text: text,
                  when: when,
                  uuid: uuid
                };
                itemList.push(item);
              }
            });
            this.activityData = itemList;
            this.loadingEvents = false;
          }
        },
        error => {
          // TODO: Do something better with the error, like notify the user.
          console.log(`Error retrieving events: ${error}`);
          this.loadingEvents = false;
        },
        () => {
          this.loadingEvents = false;
        }
      );
  }

  getHeaderQueryParams() {
    const headerQueryParams = {};
    headerQueryParams['time'] = '336';
    return headerQueryParams;
  }

  translateEvent(event, metaData): {'text': string, 'uuid': null | string } {

    let translation = metaData['translation'];
    if (translation != null) {
      let endpoint_name = ' ';
      let uuid = null;
      const data_attr = metaData['data'];
      const detail_attr = metaData['detail'];
      const data_json = {
        'name': '',
        'policy_name': '',
        'computer_name': '',
        'username': 'User',
        'ip_address': 'unknown IP Address',
        'uuid': null,
        'license_uuid': null
      };
      if (data_attr && event.data) {
        const parsed_json = JSON.parse(event.data);
        for (let key in parsed_json) {
          data_json[key] = parsed_json[key];
        }
      }

      if (detail_attr != null) {
        const detail_info: string [] = event.detail.split(',');
        let name = null;
        detail_info.forEach(detail => {
          if (detail.trimStart().startsWith(detail_attr)) {
            name = detail.split('=')[1];
          }
        });
        if (name != null) {
          endpoint_name = name;
        }
      }

      if (metaData.type == ActivityType.User) {
        if (event.username) {
          data_json['username'] = event.username;
        }
        if (event.type === 'user_login') {
          let license_uuid = data_json['license_uuid'];
          if (license_uuid != null) {
            translation = translation + '_endpoint';
          } else {
            translation = translation + '_console';
          }
        }
      } else {
        if (event.type == 'endpoint_updated') {
          endpoint_name = event.detail.split('(')[0];
          data_json['computer_name'] = endpoint_name.substring(endpoint_name.indexOf(' ') + 1);
        }
      }

      uuid = data_json['uuid'] ? data_json['uuid'] : data_json['license_uuid'];
      return {'text': this.translate.instant(translation, data_json), 'uuid': uuid};

    }
    return {'text': event.description, 'uuid': null};
  }

  onActivityToggleChanged(event) {
    this.selectedActivityFilter = event.value;
    this.getActivity();
  }

}
