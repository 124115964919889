
<mat-card appearance="raised" *ngFor="let section of policySections; let i = index;">
    <mat-card-header>
        <mat-card-title>
            {{ clientPolicyDefaults.sections[i].name }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-default-file-format
                [fileFormats]="clientPolicy.sections[i].groups"
                [defaultFileFormats]="clientPolicyDefaults.sections[i].groups"
                [showEnabledButton]="false"
                [policyExtensions]="defaultPolicyExtensions"
                [policyEnabled]="policyEnabled"
                [allowAlwaysOn]="allowAlwaysOn"
                [import]="importDefaultExts"
                (policyExtensionsChanged)="onDefaultExtensionsChanged($event)"
                (policyEnableToggleChange)="onPolicyEnableToggleChange($event)"
                (allowAlwaysOnChanged)="onAllowAlwaysOnChanged($event)">
        </app-default-file-format>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            {{ 'policy.ransomware.label' | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-ransomware-file-types
                [d3ePolicy]="d3ePolicy"
                [clientPolicyDefaults]="clientPolicyDefaults"
                (ransomExtErrorStateChanged)="onRansomExtErrorStateChanged($event)"
                (ransomExtensionsChanged)="onRansomExtensionsChanged($event)"
        >
        </app-ransomware-file-types>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            {{ 'CUSTOM_FILE_TYPES' | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!!d3ePolicy && !!clientPolicyDefaults">
        <app-custom-file-format
                [d3ePolicy]="d3ePolicy"
                [clientPolicyDefaults]="clientPolicyDefaults"
                [allowAlwaysOn]="allowAlwaysOn"
                (extensionErrorStateChanged)="onExtensionErrorStateChanged($event)"
                (policyExtensionsChanged)="onCustomExtensionsChanged($event)"
                (import)="onImportDefaultExtensionsChanged($event)">
        </app-custom-file-format>
    </mat-card-content>
</mat-card>
