import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IClientPolicyDefaults, ID3EPolicy, IPolicyExtensions, IPolicySection, IRansomExtensions} from '../../../../types/d3ePolicy';

@Component({
  selector: 'app-policy-file-types',
  templateUrl: './policy-file-types.component.html',
  styleUrls: ['./policy-file-types.component.scss']
})
export class PolicyFileTypesComponent {
  @Input() policySections: IPolicySection[] = [];
  @Input() clientPolicyDefaults: IClientPolicyDefaults;
  @Input() clientPolicy: IClientPolicyDefaults;
  @Input() defaultPolicyExtensions: IPolicyExtensions;
  @Input() policyEnabled: boolean;
  @Input() allowAlwaysOn: boolean;
  @Input() importDefaultExts: IPolicyExtensions;
  @Input() d3ePolicy: ID3EPolicy;

  @Output() policyExtensionsChanged: EventEmitter<IPolicyExtensions> = new EventEmitter<IPolicyExtensions>();
  @Output() policyEnableToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() allowAlwaysOnChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() ransomExtErrorStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ransomExtensionsChanged: EventEmitter<IRansomExtensions> = new EventEmitter<IRansomExtensions>();

  @Output() extensionErrorStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() import: EventEmitter<IPolicyExtensions> = new EventEmitter<IPolicyExtensions>();
  @Output() policyCustomExtensionsChanged: EventEmitter<IPolicyExtensions> = new EventEmitter<IPolicyExtensions>();

  onDefaultExtensionsChanged(value) {
    this.policyExtensionsChanged.emit(value);
  }

  onPolicyEnableToggleChange(value) {
    this.policyEnableToggleChange.emit(value);
  }

  onAllowAlwaysOnChanged(value) {
    this.allowAlwaysOnChanged.emit(value);
  }

  onRansomExtErrorStateChanged(value) {
    this.ransomExtErrorStateChanged.emit(value);
  }

  onRansomExtensionsChanged(value) {
    this.ransomExtensionsChanged.emit(value);
  }

  onExtensionErrorStateChanged(value) {
    this.extensionErrorStateChanged.emit(value);
  }

  onCustomExtensionsChanged(value) {
    this.policyCustomExtensionsChanged.emit(value);
  }

  onImportDefaultExtensionsChanged(value) {
    this.import.emit(value);
  }
}
