<p>
    <a [routerLink]="['/hosts']" [queryParams]="{deactivated: 'true'}">Deactivated</a> |
    <a [routerLink]="['/hosts']" [queryParams]="{deactivated: 'false'}">Activated</a>
</p>

<mat-form-field appearance="outline">
    <mat-label>Search Computer Name</mat-label>
    <input #computerNameSearch matInput (change)="computerName$.next(computerNameSearch.value)">
</mat-form-field>

<mat-form-field appearance="outline">
    <mat-label>Device Set</mat-label>
    <mat-select [value]="selectedDeviceSetId$ | async" (valueChange)="selectedDeviceSetId$.next($event)" multiple [panelWidth]="null">
        @for (deviceSet of deviceSets$ | async; track deviceSet.id) {
            <mat-option [value]="deviceSet.id">{{deviceSet.name}}</mat-option>
        }
    </mat-select>
</mat-form-field>

<!-- add table sorting (programmatically setting sorts on table; see: assign-licenses component) -->

<button mat-raised-button color="primary" [disabled]="button1Disabled$ | async" (click)="button1Clicked()">Button 1</button>
<button mat-raised-button color="primary" [disabled]="button2Disabled$ | async" (click)="button2Clicked()">Button 2</button>

<p>Total: {{total$ | async}}</p>
<p>Shown: {{shown$ | async}}</p>

<div style="text-align: right">
    <app-column-chooser saveId="hosts" [elements]="columns" (change)="columnsToDisplay = $event"></app-column-chooser>
</div>

<button mat-raised-button (click)="extend()" [disabled]="(shown$ | async) == (total$ | async)">More...</button>

@if (rowColorCss$ | async; as rowColorCss) {
    <mat-table matSort [dataSource]="hosts$" (matSortChange)="sort$.next($event)">
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox>
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let endpoint">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(endpoint) : null"
                              [checked]="selection.isSelected(endpoint)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="computerName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Computer Name</mat-header-cell>
            <mat-cell *matCellDef="let endpoint">{{endpoint.computer_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="deviceSetName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</mat-header-cell>
            <mat-cell *matCellDef="let endpoint">{{endpoint.device_set_name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let endpoint">
                @if (!!endpoint.deactivated) {
                    <span>Deactivated</span>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="systemSerial">
            <mat-header-cell *matHeaderCellDef mat-sort-header>System Serial</mat-header-cell>
            <mat-cell *matCellDef="let endpoint">{{endpoint.system_serial}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Data Defense Version</mat-header-cell>
            <mat-cell *matCellDef="let endpoint">{{endpoint.version}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row
                *matRowDef="let endpoint; columns: columnsToDisplay"
                (click)="selectedEndpoint$.next(endpoint)"
                [ngClass]="rowColorCss[endpoint.device_set_id]"></mat-row>
    </mat-table>
}

@if (selectedEndpoint$ | async; as endpoint) {
    <div class="backdrop" (click)="selectedEndpoint$.next(null)"></div>
    <div class="panel">
        <h1>{{endpoint.computer_name}} JSON</h1>
        <pre>{{endpoint | json}}</pre>
    </div>
}
