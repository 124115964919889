<div *ngIf="securityRequestsEnabled && user$ | async as user" class="disk_config">
<!--    <span>{{currentRequest?.status}}</span>-->
<!--    <span *ngIf="(currentRequest == null || currentRequest.found == false) && drive.disk_config_state == 1" class="mdi mdi-key config-icon" title="{{'ssd.disk_config.key_tooltip' | translate}}" [class.disabled]="user.org_type['value'] != customerType" (click)="requestBackupConfigAccess(drive)"></span>-->
    <span *ngIf="(user.org_type['value'] == customerType) && (currentRequest == null || currentRequest.found == false) && drive.disk_config_state == 1" class="mdi mdi-key config-icon has_pointer" matTooltip="{{'ssd.disk_config.key_tooltip' | translate}} {{ 'ssd.disk_config.click_to_request'  | translate}}"  (click)="requestBackupConfigAccess(drive)"></span>
    <span *ngIf="(user.org_type['value'] != customerType)" class="mdi mdi-key config-icon" matTooltip="{{'ssd.disk_config.key_tooltip' | translate}}"></span>


    <div *ngIf="currentRequest?.status == Status.APPROVED || currentRequest?.status == Status.ACCEPTED; else deniedContainer">
        <span class="mdi mdi-tray-arrow-down config-icon has_pointer" matTooltip="{{'ssd.disk_config.download_key' | translate}}" (click)="downloadDiskConfig(currentRequest.id)"></span>
    </div>

    <ng-template #deniedContainer>
        <div *ngIf="currentRequest?.status == Status.DENIED; else pendingContainer" class="status-container">
            <span class="mdi mdi-close-circle-outline denied-config-icon has_pointer" matTooltip="{{'ssd.disk_config.request_denied' | translate}}" (click)="viewBackupConfigStatus(currentRequest.id)"></span>
        </div>
    </ng-template>

    <ng-template #pendingContainer>
        <div *ngIf="currentRequest?.status == Status.PENDING">
            <span class="mdi mdi-cursor-pointer config-icon has_pointer" matTooltip="{{'ssd.disk_config.request_pending' | translate}}" (click)="viewBackupConfigStatus(currentRequest.id)"></span>
        </div>
    </ng-template>
</div>
