import {Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {IGroupSettings} from '../group-settings';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../services/user-service';
import {IManageUser, SearchUsersResponse} from '../../../../types/user';
import {HeaderService} from '../../../header.service';
import { switchMap } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {UniqueGroupNameValidator} from '../../../shared/validators/custom-validators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { ALL_ORGS_ID } from '../../../../types/group';

interface ISortableUser extends IManageUser {
  selected: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-group-details-user',
  templateUrl: './group-details-user.component.html',
  styleUrls: ['./group-details-user.component.scss']
})
export class GroupDetailsUserComponent implements OnChanges {
  search: string = '';
  selectedIds: Map<number, boolean> = new Map<number, boolean>();

  @Input() groupSettings: IGroupSettings;
  @Output() groupSettingsChange: EventEmitter<IGroupSettings> = new EventEmitter<IGroupSettings>();
  @Input() groupNameValidator: UniqueGroupNameValidator;

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  form: UntypedFormGroup;
  dataSource: MatTableDataSource<IManageUser> = new MatTableDataSource<ISortableUser>();
  columns = ['select', 'name', 'email'];
  selection: SelectionModel<ISortableUser> = new SelectionModel<ISortableUser>(true);
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  formSkeletonTheme = {
    height: '40px',
    width: '25em',
    margin: '10px',
    padding: '0'
  };

  tableSkeletonTheme = {
    height: '40px',
    width: '90%',
    margin: '10px',
    padding: '0'
  };

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly userSvc: UserService,
    private readonly headerSvc: HeaderService,
  ) {
    this.form = fb.group({
      name: ['', Validators.required],
      description: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const settingsFirstChange = changes['groupSettings']?.firstChange;
    const settingsHasChanged = changes['groupSettings']?.currentValue !== changes['groupSettings']?.previousValue;
    if (settingsFirstChange || settingsHasChanged) {
      this.headerSvc.orgSelectedChanged.pipe(
        switchMap(selectedOrg => {
          return this.searchUsers(selectedOrg);
        }),
        untilDestroyed(this),
      ).subscribe((users: SearchUsersResponse) => {
        this.selectedIds.clear();
        this.selection.clear();
        changes['groupSettings'].currentValue.members.forEach(userId => this.selectedIds.set(userId, true));
        this.setUsers(users)
        this.dataSource.sortingDataAccessor = (user: ISortableUser, sortHeaderId: string): string | number => {
          switch (sortHeaderId) {
            case "select":
              return (user.selected) ? 1 : 2;
            case "name":
              return user.fname.toLowerCase();
            default:
              if ((typeof user[sortHeaderId]) === 'string') {
                return user[sortHeaderId].toLowerCase();
              }
              return user[sortHeaderId];
          }
        };
        this.form.setValue({
          name: changes['groupSettings'].currentValue.name,
          description: changes['groupSettings'].currentValue.description
        });
        this.loaded$.next(true);
      });
    }

    const validatorFirstChange = changes['groupNameValidator']?.firstChange;
    const validatorHasChanged = changes['groupNameValidator']?.currentValue !== changes['groupNameValidator']?.previousValue;
    if (validatorFirstChange|| validatorHasChanged) {
      this.form.controls.name.setAsyncValidators([
        changes.groupNameValidator.currentValue?.validate.bind(this.groupNameValidator)
      ]);
    }

  }

  searchUsers(selectedOrgId: string): Observable<SearchUsersResponse> {
    const _selectedOrgId = selectedOrgId == ALL_ORGS_ID ? '' : selectedOrgId;
    return this.userSvc.api_search_users(this.search, _selectedOrgId);
  }

  getUsers() {
    this.searchUsers(this.headerSvc.orgSelectedChanged.value).subscribe(this.setUsers.bind(this));
  }

  setUsers(usersResponse: SearchUsersResponse) {
    this.selection.clear();
    this.dataSource.data = usersResponse.users;
    this.dataSource.sort = this.sort.toArray()[0];
    for (let user of usersResponse.users as ISortableUser[]) {
      if (!this.selectedIds.has(user.id)) {
        this.selectedIds.set(user.id, false);
        user.selected = false;
      }
      if (this.selectedIds.get(user.id)) {
        this.selection.select(user);
        user.selected = true;
      } else {
        user.selected = false;
      }
    }
    this.resort();
  }

  emitChanges() {
    const members: number[] = [];
    for (let userId of this.selectedIds.keys()) {
      if (this.selectedIds.get(userId)) {
        members.push(userId);
      }
    }
    const settings: IGroupSettings = {
      groupId: this.groupSettings.groupId,
      name: this.form.value.name,
      description: this.form.value.description,
      members: members,
    };
    this.groupSettingsChange.emit(settings);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach((user: ISortableUser) => {
        this.selectedIds.set(user.id, false);
        user.selected = false;
      });
    } else {
      this.dataSource.data.forEach((user: ISortableUser) => {
          this.selection.select(user);
          this.selectedIds.set(user.id, true);
          user.selected = true;

      });
    }
  }

  toggleAll() {
    this.masterToggle();
    this.emitChanges();
  }

  toggleRow(toggle: boolean, row: ISortableUser) {
    if (toggle) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.selectedIds.set(row.id, toggle);
    row.selected = toggle;
    this.resort();
    this.emitChanges();
  }

  resort() {
    const sort = this.sort.toArray()[0];
    sort.sortChange.emit({active: sort.active, direction: sort.direction});
  }

}
