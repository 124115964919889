<h2 mat-dialog-title>{{'assign.generate.title' | translate}}</h2>

<mat-dialog-content class="main-content">
    <p>{{ 'assign.generate.confirm-text' | translate : {count: how_many} }}</p>
    <p>{{ 'assign.generate.single-license' | translate}}</p>
    <form [formGroup]="formGroup">
        <mat-form-field appearance="outline">
            <mat-label>{{'MAINT_EXPIRES' | translate}}</mat-label>
            <input matInput formControlName="maintEnd" name="maintEnd" [value]="data.maintEnd" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-raised-button type="button" color="primary" (click)="onCancelClick()">{{'CANCEL' | translate}}</button>
    <button mat-raised-button type="button" color="primary" (click)="onSaveClick()" id="accept-button" [disabled]="!formGroup.valid">{{'SAVE' | translate}}</button>
</div>
