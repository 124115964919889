import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UserService} from '../../../services/user-service';
import {LOAD_USER, USER_LOADED_SUCCESS} from './user.actions';
import {exhaustMap, map} from 'rxjs/operators';


@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(LOAD_USER),
    exhaustMap(() => this.userService.api_get_user_info().pipe(
      map(user => ({type: USER_LOADED_SUCCESS, user: user})),
    ))
  ));

  constructor(private readonly actions$: Actions,
              private readonly userService: UserService) {
  }
}