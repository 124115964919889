import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPbaUser} from '../../../../types/d3ePolicy';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {IPbaUserDialogOptions, PbaUserDialogComponent} from './pba-user-dialog/pba-user-dialog.component';

@Component({
  selector: 'app-pba-users',
  templateUrl: './pba-users.component.html',
  styleUrl: './pba-users.component.scss'
})
export class PbaUsersComponent {
  @Input() pbaUsers: IPbaUser[];
  @Output() pbaUsersChange: EventEmitter<IPbaUser[]> = new EventEmitter<IPbaUser[]>();
  @Output() onChanged:  EventEmitter<void> = new EventEmitter<void>();

  selection = new SelectionModel<IPbaUser>(true, []);

  displayedColumns = ['select', 'username', 'email', 'administrator'];

  constructor(private readonly dialog: MatDialog) {
  }

  addPbaUser() {
    const options: IPbaUserDialogOptions = {
      pbaUser: null,
      existingUsers: this.pbaUsers,
    };
    this.dialog.open(PbaUserDialogComponent, {data: options}).afterClosed().subscribe({
      next: result => {
        if (result) {
          this.pbaUsers = [...this.pbaUsers, result].sort((a, b) => a.username.localeCompare(b.username));
          this.pbaUsersChange.emit(this.pbaUsers);
          this.onChanged.emit();
        }
      }
    });
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.pbaUsers.forEach(pbaUser => {
        this.selection.select(pbaUser);
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.pbaUsers.length;
    return numSelected === numRows;
  }

  checkboxToggle($event, pbaUser) {
    this.selection.toggle(pbaUser);
  }

  deleteSelected() {
    const selectedUsernames = this.selection.selected.map(u => u.username);
    this.pbaUsers = this.pbaUsers.filter(u => selectedUsernames.indexOf(u.username) === -1).sort((a, b) => a.username.localeCompare(b.username));
    this.pbaUsersChange.emit(this.pbaUsers);
    this.onChanged.emit();
  }

  editPbaUser(index: number) {
    const options: IPbaUserDialogOptions = {
      pbaUser: this.pbaUsers[index],
      existingUsers: this.pbaUsers,
    };
    this.dialog.open(PbaUserDialogComponent, {
      data: options,
    }).afterClosed().subscribe({
      next: result => {
        if (result) {
          this.pbaUsers[index] = result;
          this.pbaUsers = [...this.pbaUsers.sort((a, b) => a.username.localeCompare(b.username))];
          this.pbaUsersChange.emit(this.pbaUsers);
          this.onChanged.emit();
        }
      }
    });
  }

}
