import {Component, Input, OnInit} from '@angular/core';
import {ISubscription, LicenseType} from '../../../../types/subscription';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @Input() subscription: ISubscription;

  public readonly LicenseType = LicenseType;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
  }

  isExpired(): boolean {
    return new Date(this.subscription.exp_timestamp) < new Date();
  }

  countToString(count: number) : string {
    return count < 1000000 ? count : this.translate.instant('UNLIMITED');
  }
}
