import { CanDeactivateFn } from '@angular/router';
import {Component, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '../dialogs/alert/alert.component';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface DeactivatableComponent extends Component {

  // This method is called on the component to determine if the page has unsaved modifications.
  getIsDirty(): boolean;

  // Called from within canDeactivate(), this method give the page a chance to
  // get the response and respond to it to clean up or similar.
  // the boolean argument is true if Discard(accept) is selected.
  canDeactivateResult(result: boolean): void;

  // A method called from within the canDeactivateUserSelector that notifies the
  // page that Discard has been selected.  This method should reset the dirty flag
  // returned by the getIsDirty() method as well.
  userValueChanged(): void;
}

export function createDiscardDialog(dialog: MatDialog, translate: TranslateService): Observable<any> {

  const discardChangesTitle = translate.instant('DISCARD_CHANGES_TITLE');
  const stay = translate.instant('STAY');
  const discard = translate.instant('DISCARD');

  return dialog.open(AlertDialogComponent, {
    width: '450px',
    data: {
      title: '',
      content: discardChangesTitle,
      rejectBtnName: stay,
      acceptBtnName: discard,
      showRejectBtn: true,
    },
  }).afterClosed();
}

export const pageGuard: CanDeactivateFn<unknown> = (component: DeactivatableComponent, currentRoute, currentState, nextState) => {
  const dialog = inject(MatDialog);
  const translate = inject(TranslateService);

  if (component.getIsDirty()) {
    return createDiscardDialog(dialog, translate).pipe(
      tap(result => !!component?.canDeactivateResult && component?.canDeactivateResult(result))
    );
  }
  return true;
};
