import {createAction, props} from '@ngrx/store';
import {IManageUser} from '../../../types/user';

export const LOAD_USER = '[User] Load';
export const USER_LOADED_SUCCESS = '[User] Loaded Success';

export const loadUser = createAction(LOAD_USER);

export const userLoadedSuccess = createAction(
  USER_LOADED_SUCCESS,
  props<{ user: IManageUser }>()
);
