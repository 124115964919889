import {createAction, props} from '@ngrx/store';
import {IGroup} from '../../../types/group';

export const LOAD_ORGANIZATIONS = '[Organizations] Load';
export const ORGANIZATIONS_LOADED_SUCCESS = '[Organizations] Loaded Success';
export const UPDATE_ORGANIZATIONS = '[Organizations] Update';

export const loadOrganizations = createAction(LOAD_ORGANIZATIONS);

export const organizationsLoadedSuccess = createAction(
  ORGANIZATIONS_LOADED_SUCCESS,
  props<{organizations: IGroup[]}>()
);
