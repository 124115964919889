import { ChangeDetectorRef, Component, DestroyRef, inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {GroupsService, GroupType} from '../../../../services/groups-service';
import {ActivatedRoute, Router} from '@angular/router';
import {IGroupSettings} from '../group-settings';
import {HeaderService} from '../../../header.service';
import {TranslateService} from '@ngx-translate/core';
import {EncryptionService} from '../../../../services/encryption.service';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import { map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {D3eService} from '../../../../services/d3e-service';
import {IDeviceSetGetResponse, IDeviceSetRequest} from '../../../../types/device-sets';
import {UniqueGroupNameValidator} from '../../../shared/validators/custom-validators';
import {GroupDetailsUserComponent} from '../group-details-user/group-details-user.component';
import {GroupDetailsDeviceComponent} from '../group-details-device/group-details-device.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {RestConfigService} from '../../../../services/config-service';
import {IRestConfig} from '../../../../types/config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationService } from '../../../../services/notification-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupDetailsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  public readonly GroupType = GroupType
  groupType: GroupType = GroupType.device
  groupId: number;
  errors: boolean;
  saving: boolean;
  selectedOrgId: string = 'All';
  restConfig$: Observable<IRestConfig>;
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  groupSettings: IGroupSettings = {
    groupId: -1,
    name: '',
    description: '',
    policyId: 0,
    members: [],
  };

  groupNameValidator: UniqueGroupNameValidator;

  @ViewChild(GroupDetailsUserComponent) userDetails: GroupDetailsUserComponent;
  @ViewChild(GroupDetailsDeviceComponent) deviceDetails: GroupDetailsDeviceComponent;

  constructor(
    private readonly router: Router,
    private readonly headerService: HeaderService,
    private readonly translate: TranslateService,
    private readonly encryptionService: EncryptionService,
    private readonly d3eService: D3eService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly groupsService: GroupsService,
    private readonly changeDectectorRef: ChangeDetectorRef,
    private readonly restConfigService: RestConfigService,
    private readonly notification: NotificationService
  ) {
    headerService.setTitle(translate.instant('groups.detail-title'));
    headerService.setSubtitle('');
    headerService.setIcon('mdi-format-list-bulleted');
    headerService.setNavMenuText('');
    headerService.showUserFilter(true);
    headerService.disableUserFilter();

    this.restConfig$ = this.restConfigService.getRestConfig();

    activatedRoute.paramMap.pipe(
      tap(() => this.loaded$.next(false)),
      withLatestFrom(headerService.orgSelectedChanged),
      switchMap(([paramMap, selectedOrgId]) => {
        if (selectedOrgId === 'All') {
          selectedOrgId = '';
        }
        this.selectedOrgId = selectedOrgId;
        this.groupNameValidator = new UniqueGroupNameValidator(selectedOrgId, groupsService);
        if (paramMap.get('type')) {
          this.groupType = GroupType[paramMap.get('type')];
          this.groupId = parseInt(paramMap.get('id'));
          if (this.groupType === GroupType.device) {
            return this.d3eService.getDeviceSet(this.groupId.toString(), selectedOrgId).pipe(
              map((device: IDeviceSetGetResponse) => {
                return {
                  groupId: this.groupId,
                  name: device.name,
                  description: device.description,
                  policyId: device.policy_id,
                  members: device.devices.map(d => d.id)
                }
              })
            );
          } else if (this.groupType === GroupType.user) {
            return encryptionService.getSharedKey(this.groupId, selectedOrgId);
          }
        }
        this.loaded$.next(true);
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (groupSettings) => {
        this.groupNameValidator.setInitialName(groupSettings.name);
        this.groupSettings = groupSettings as IGroupSettings;
        this.loaded$.next(true);
      },
      complete: () => {
        this.loaded$.next(true);
      }
    });
  }

  ngOnInit(): void {
  }

  returnToGroupsPage() {
    this.router.navigate(["/groups"]);
  }

  cancelButtonClick() {
    this.returnToGroupsPage();
  }

  saveGroupButtonClick() {
    this.changeDectectorRef.detectChanges();
    const form = (this.deviceDetails || this.userDetails).form;
    this.saving = true;
    form.statusChanges.pipe(
      skipWhile(v => v === 'PENDING'),
      take(1),
      untilDestroyed(this),
    ).subscribe(status => {
      if (status !== 'VALID') {
        this.saving = false;
        return;
      }
      if (this.groupId) {
        if (this.groupType === GroupType.user) {
          this.putUserGroup();
        } else if (this.groupType === GroupType.device) {
          this.putDeviceSet();
        }
      } else {
        if (this.groupType === GroupType.user) {
          this.postUserGroup();
        } else if (this.groupType === GroupType.device) {
          this.postDeviceSet();
        }
      }
    });
    form.updateValueAndValidity();
  }

  getSharedGroup() {
    this.encryptionService.getSharedKey(this.groupId).subscribe(groupSettings => this.groupSettings = groupSettings);
  }

  putUserGroup() {
    this.encryptionService.putSharedKey(this.groupId, this.groupSettings, this.selectedOrgId).subscribe(() => this.returnToGroupsPage());
  }

  postUserGroup() {
    this.encryptionService.postSharedKey(this.groupSettings, this.selectedOrgId).subscribe(() => this.returnToGroupsPage());
  }

  putDeviceSet() {
    const request = this.createDeviceSetRequest();
    this.d3eService.updateDeviceSet(this.groupId.toString(), request, this.selectedOrgId)
    .pipe(
      switchMap(() => {
        return this.d3eService.assignDeviceSet(this.selectedOrgId, this.groupSettings.members, this.groupId);
      })
    ).subscribe(() => this.returnToGroupsPage());
  }

  postDeviceSet() {
    this.d3eService.createDeviceSet(this.createDeviceSetRequest(), this.selectedOrgId)
      .subscribe({
        next: () => this.returnToGroupsPage(),
        error: error => {
          this.saving = false;
          if (error === "duplicate") {
            this.notification.showError('The selected endpoint(s) are already assigned to a group.');
          } else {
            this.notification.showError(error);
          }
        },
        complete: () => {
          this.saving = false;
        }
      });
  }

  createDeviceSetRequest() {
    const request: IDeviceSetRequest = {
      name: this.groupSettings.name,
      description: this.groupSettings.description,
      policy_id: this.groupSettings.policyId,
      devices: this.groupSettings.members,
    };
    return request;
  }
}
