
<div *ngIf="results$ | async as results">

    <mat-list *ngIf="results.endpoints.length > 0">
        <div mat-subheader>{{'APP_ENDPOINTS' | translate}}</div>
        <mat-list-item *ngFor="let el of results.endpoints">
            <i class="mdi mdi-monitor"></i>
            <a [routerLink]="['/device', el.id]">{{el.name}}</a>
        </mat-list-item>
    </mat-list>

    @if (user$ | async; as user) {
        @if (user.role == UserRole.ADMIN) {
            <mat-list *ngIf="results.endpointGroups.length > 0">
                <div mat-subheader>{{'APP_ENDPOINT_GROUPS' | translate}}</div>
                <mat-list-item *ngFor="let el of results.endpointGroups">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <a [routerLink]="['/group-details', 'device', el.id]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>

            <mat-list *ngIf="results.userGroups.length > 0">
                <div mat-subheader>{{'APP_USER_GROUPS' | translate}}</div>
                <mat-list-item *ngFor="let el of results.userGroups">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <a [routerLink]="['/group-details', 'user', el.id]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>

            <mat-list *ngIf="results.policies.length > 0">
                <div mat-subheader>{{'APP_POLICIES' | translate}}</div>
                <mat-list-item *ngFor="let el of results.policies">
                    <i class="mdi mdi-wallet-membership"></i>
                    <a [routerLink]="['/policy', el.id.toString()]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>
        }
    }

    <mat-list *ngIf="results.users.length > 0">
        <div mat-subheader>{{'APP_USERS' | translate}}</div>
        <mat-list-item *ngFor="let el of results.users">
            <i class="mdi mdi-account"></i>
            <a [routerLink]="['/users']" [queryParams]="{'search': el.name}">{{el.name}}</a>
        </mat-list-item>
    </mat-list>

</div>