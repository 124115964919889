import {Component, Input, OnChanges} from '@angular/core';
import {IDeviceState, INetwork} from '../../../../types/subscription';
import {ISensor} from '../../../../types/d3ePolicy';

@Component({
  selector: 'app-threat-sensors',
  templateUrl: './threat-sensors.component.html',
  styleUrls: ['./threat-sensors.component.scss']
})
export class ThreatSensorsComponent implements OnChanges {
  @Input() deceptionFiles: string[];
  @Input() deceptionUrls: string[];
  @Input() networks: INetwork[];
  @Input() state: IDeviceState;
  @Input() sensorConfigs: ISensor[];

  ports: string[] = [];

  networkColumns: string[] = ['icon', 'name', 'trusted', 'firstConnected', 'ipv4Address', 'gateway_mac'];

  readonly FILE_DECEPTION = 12;
  readonly NET_DECEPTION = 13;
  fileDeceptionSensor: ISensor;
  networkDeceptionSensor: ISensor;

  readonly AV_TETHER = 24;
  avTetherSensor: ISensor;

  readonly TRUSTED_NET = 23;
  trustedNetwork: ISensor;

  readonly USB_STORAGE = 1;
  removableStorage: ISensor;

  ngOnChanges(): void {
    this.ports = this.deceptionUrls?.map(url => url?.split(":")[2]);  // url is like: tcp://HOSTNAME:port

    const sensors: {[sensorType: number]: ISensor} = this.sensorConfigs?.reduce((acc, sensor) => {
      acc[sensor.sensorType] = sensor;
      return acc;
    }, {});

    if (sensors) {
      this.fileDeceptionSensor = sensors[this.FILE_DECEPTION];
      this.networkDeceptionSensor = sensors[this.NET_DECEPTION];
      this.avTetherSensor = sensors[this.AV_TETHER];
      this.trustedNetwork = sensors[this.TRUSTED_NET];
      this.removableStorage = sensors[this.USB_STORAGE];
    }
  }
}
