<h2 mat-dialog-title>{{ 'END_LOCK_TITLE' | translate}}</h2>

<mat-dialog-content>
    <p>{{ 'END_LOCK_CONTENT' | translate }}</p>
    <mat-checkbox [(ngModel)]="allowUserUnlock">{{ 'END_LOCK_ALLOW_USER_UNLOCK' | translate }}</mat-checkbox>
</mat-dialog-content>

<div mat-dialog-actions class="button-wrapper" align="end">
    <button mat-stroked-button (click)="cancel()" type="button" color="primary">{{ 'CANCEL' | translate }}</button>
    <button mat-stroked-button (click)="confirm()" type="button" color="primary">{{ 'CONFIRM' | translate }}</button>
</div>
