<h2 mat-dialog-title>{{'endpoints.trasnfer-license-dialog.title' | translate}}</h2>

<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>{{'endpoints.trasnfer-license-dialog.selector-label' | translate}}</mat-label>
        <mat-select #subscriptionSelect>
            <mat-option id="multiple-option" *ngFor="let subscription of data.subscriptions" [value]="subscription">
                <span matListItemTitle>{{subscription.subscription_id}} </span>
                <span matListItemLine>{{'endpoints.trasnfer-license-dialog.n-available' | translate : {n: subscription.count - subscription.used} }} ({{ 'EXPIRES' | translate }}: {{ subscription.exp_timestamp | date : 'shortDate' }}) </span>
                <span matListItemLine>{{subscription.company || subscription.email}} </span>
                <span matListItemLine>{{subscription.stripe_product.name}} </span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="button-wrapper">
    <button id="transferLicenseDialogCancel" mat-raised-button (click)="onCancel()" type="button" color="primary">{{'CANCEL' | translate}}</button>
    <button id="transferLicenseDialogSave" mat-raised-button (click)="onSubmit(subscriptionSelect.value)" [disabled]="!subscriptionSelect.value" type="button" color="primary">{{'OK' | translate}}</button>
</div>


