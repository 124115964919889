
<div *ngIf="tree$ | async as tree">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let reseller of tree">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Reseller: {{reseller.org.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-orgs-overview-org
                    [org]="reseller.org"
                    [children]="reseller.children"
                    [users]="reseller.users"></app-orgs-overview-org>
        </mat-expansion-panel>
    </mat-accordion>
</div>

