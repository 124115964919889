<h2 mat-dialog-title>{{'policy.pba.pba-users.user-dialog.title' | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="form" autocomplete="off">
        <div class="columns">
            <div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ADM_UNAME' | translate }}</mat-label>
                        <input matInput type="text" name="username" formControlName="username" autocomplete="off">
                        @if (form.controls.username.hasError('usernameExists')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.username-exists' | translate}}</mat-error>
                        } @else if (form.controls.username.hasError('required')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.username-required' | translate}}</mat-error>
                        }
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ADM_EMAIL' | translate }}</mat-label>
                        <input matInput type="text" name="email" formControlName="email">
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ADO_PASS' | translate }}</mat-label>
                        <input matInput type="password" name="random3" formControlName="password" #passwordInput autocomplete="new-password">
                        <button matSuffix mat-icon-button (click)="passwordInput.type = passwordInput.type == 'text' ? 'password' : 'text'">
                            @if (passwordInput.type == "password") {
                                <i class="mdi mdi-eye-off"></i>
                            } @else {
                                <i class="mdi mdi-eye"></i>
                            }
                        </button>
                        @if (form.controls.password.hasError('required')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.password-required' | translate}}</mat-error>
                        } @else if (form.controls.password.hasError('pattern')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.password-invalid' | translate}}</mat-error>
                        } @else if (form.controls.password.hasError('passwordValid')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.password-invalid' | translate}}</mat-error>
                        }
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'DIV_CONF_PW' | translate }}</mat-label>
                        <input matInput type="{{passwordInput.type}}" name="confirm" formControlName="confirm" autocomplete="new-password">
                        <button matSuffix mat-icon-button (click)="passwordInput.type = passwordInput.type == 'text' ? 'password' : 'text'">
                            @if (passwordInput.type == "password") {
                                <i class="mdi mdi-eye-off"></i>
                            } @else {
                                <i class="mdi mdi-eye"></i>
                            }
                        </button>
                        @if (form.hasError('passwordsMatch')) {
                            <mat-error>{{'policy.pba.pba-users.user-dialog.errors.passwords-match' | translate}}</mat-error>
                        }
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="columns">
            <div>
                <mat-checkbox formControlName="administrator">{{ 'ADM_ADMIN' | translate }}</mat-checkbox>
            </div>
            <div>
                <button type="button" mat-raised-button (click)="passwordRequirements()">{{'policy.pba.pba-users.user-dialog.password-requirements' | translate}}</button>
            </div>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onCancel()" type="button">{{ 'CANCEL' | translate }}</button>
    <button mat-raised-button (click)="onSave()" type="button" color="primary" [disabled]="form.invalid">
        @if (!!options?.pbaUser) {
            {{ 'APP_UPDATE' | translate }}
        } @else {
            {{ 'G_ADD' | translate }}
        }
    </button>
</mat-dialog-actions>