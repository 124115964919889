import {Component, Input, OnInit} from '@angular/core';
import {IGroup} from '../../../../../types/group';
import {IOrgTreeNode} from '../../../../../types/org-tree';
import {IUser} from '../../../../../types/user';

@Component({
  selector: 'app-orgs-overview-org',
  templateUrl: './orgs-overview-org.component.html',
  styleUrls: ['./orgs-overview-org.component.scss']
})
export class OrgsOverviewOrgComponent implements OnInit {

  @Input() org: IGroup;
  @Input() children: IOrgTreeNode[];
  @Input() users: IUser[];

  constructor() { }

  ngOnInit(): void {
  }

}
