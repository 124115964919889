<div class="details-container">
    @if (loaded$ | async) {
      <form [formGroup]="form">
          <div class="form-container">
            <div class="form-element">
              <mat-form-field appearance="outline" class="group-form-field">
                  <mat-label>{{'groups.forms.group-name' | translate}}</mat-label>
                  <input matInput formControlName="name" (change)="emitChanges()" />
                  <mat-error *ngIf="form.controls.name?.errors?.exists">{{'groups.name-exists' | translate}}</mat-error>
                  <mat-error *ngIf="form.controls.name?.errors?.required">{{'groups.name-required' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div class="form-element">
              <mat-form-field appearance="outline" class="group-form-field">
                  <mat-label>{{'groups.forms.group-description' | translate}}</mat-label>
                  <input matInput formControlName="description" (change)="emitChanges()" />
              </mat-form-field>
            </div>
          </div>
      </form>
    } @else {
        <ngx-skeleton-loader appearance="line" [theme]="formSkeletonTheme" count="3"></ngx-skeleton-loader>
    }
    <hr />
    <div class="table-container">
        <div class="user-table-header table-header">
            <div class="search-input-wrapper">
                <mat-form-field appearance="outline">
                    <mat-label>{{'DSS_SEARCH' | translate}}</mat-label>
                    <input matInput [(ngModel)]="search" appDebounceInput (debounceInput)="getUsers()"
                           [debounceTime]="250" type="search" />
                </mat-form-field>
            </div>

        </div>
        <div class="table-wrapper">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSortActive="name" matSort>

                <ng-container matColumnDef="select">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header class="checkbox-cell">
                        <mat-checkbox (change)="toggleAll()"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="checkbox-cell">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="toggleRow($event.checked, row)"
                                      [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME' | translate}}</th>
                    <td mat-cell *matCellDef="let user"> {{user.fname}} {{user.lname}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>{{'EMAIL' | translate}}</th>
                    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;">
                </tr>

            </table>

            @if (!(loaded$ | async)) {
                <ngx-skeleton-loader appearance="line" [theme]="tableSkeletonTheme" count="6"></ngx-skeleton-loader>
            }
        </div>
    </div>
</div>