import {IManageUser} from '../../types/user';
import {IGroup} from '../../types/group';
import {ActionReducer, createAction, MetaReducer} from '@ngrx/store';

export interface AppState {
  user: IManageUser;
  organizations: IGroup[];
}

export const LOGOUT = '[Meta] Logout User';

export const logout = createAction(LOGOUT);

const clearState = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    if (action?.type === LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  }
};

export const metaReducers: MetaReducer[] = [clearState];
