import {TranslateService} from '@ngx-translate/core';

export const getYesNoTranslationMap = (translateSvc: TranslateService): {[key: number]: string} => {
  return {
    0: translateSvc.instant('NO'),
    1: translateSvc.instant('YES'),
  };
};

export const getApproverStatusTranslationMap = (translateSvc: TranslateService): {[key: number]: string} => {
  return {

    // SecurityApproverState.NOT_APPROVER
    0: translateSvc.instant('NONE'),

    // SecurityApproverState.NOT_APPROVER
    1: translateSvc.instant('AIV_APPROVER'),

    // SecurityApproverState.NOT_APPROVER
    2: translateSvc.instant('security-approver.pending-removal'),

    // SecurityApproverState.NOT_APPROVER
    3: translateSvc.instant('security-approver.pending-approver'),

  };
};

export const getActiveStatusTranslationMap = (translateSvc: TranslateService): {[key: number]: string} => {
  return {
    0: translateSvc.instant('INACTIVE'),
    1: translateSvc.instant('ACTIVE'),
  };
};



