import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

export interface ILockEndpointsDialogResult {
  allowUserUnlock: boolean;
}

@Component({
  selector: 'app-lock-endpoints-dialog',
  templateUrl: './lock-endpoints-dialog.component.html',
  styleUrls: ['./lock-endpoints-dialog.component.scss']
})
export class LockEndpointsDialogComponent implements OnInit {

  allowUserUnlock: boolean;

  constructor(
    public dialogRef: MatDialogRef<LockEndpointsDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const result: ILockEndpointsDialogResult = {allowUserUnlock: this.allowUserUnlock};
    this.dialogRef.close(result)
  }

}
