import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {API_URL} from '../shared/api';
import {map} from 'rxjs/operators';

export enum GroupType {
  user = "user",
  device = "device"
}

export interface IGroupSummary {
  id: number;
  name: string;
  count: number;
  type: GroupType;
  expires: null | Date;
}

export interface IGroupSummariesResponse {
  success: boolean;
  groups: IGroupSummary[];
}

export interface IGroupExistsResponse {
  success: boolean;
  exists: boolean;
}

export interface IDefaultGroupResponse {
  success: boolean;
  default_group: IGroupSummary;
}

@Injectable({providedIn: 'root'})
export class GroupsService extends BaseService {

  getGroupSummaries(searchTerm: string, selectedOrgId: string): Observable<IGroupSummary[]> {
    const query = [['q', searchTerm]];
    if ((selectedOrgId === 'All') ? '' : selectedOrgId) {
      query.push(['selectedOrgId', selectedOrgId]);
    }
    const url = this.getUrl(API_URL.API_V1_GROUPS, query);
    return this.http.get<IGroupSummariesResponse>(url).pipe(
      map(response => {
        if (response.success) {
          return response.groups;
        }
        throw new Error(`${url}: failed`);
      })
    );
  }

  getNameExists(name: string, selectedOrgId: string = ''): Observable<boolean> {
    selectedOrgId = (selectedOrgId === 'All') ? '' : selectedOrgId;
    const url = this.getUrl(API_URL.API_V1_GROUPS_NAME_EXISTS, [["name", name], ["selectedOrgId", selectedOrgId]]);
    return this.http.get<IGroupExistsResponse>(url).pipe(
      map(response => {
        if (response.success) {
          return response.exists;
        }
        throw new Error(`${url}: failed`);
      })
    )
  }

  getDefaultGroup(selectedOrgId: string): Observable<IGroupSummary> {
    const query = [];
    if ((selectedOrgId === 'All') ? '' : selectedOrgId) {
      query.push(['selectedOrgId', selectedOrgId]);
    }
    const url = this.getUrl(API_URL.API_V1_GROUP_DEFAULT, query);
    return this.http.get<IDefaultGroupResponse>(url).pipe(
      map(response => {
        if (response.success) {
          return response.default_group;
        }
        throw new Error(`${url}: failed`);
      })
    )
  }

}