
<mat-card appearance="raised">
    <mat-card-content>
        <div>Contact E-mail: {{org.contact_email}}</div>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised" *ngIf="children?.length > 0">
    <mat-card-header>
        <mat-card-title>Child Orgs</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let child of children">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{child.org.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-orgs-overview-org
                        [org]="child.org"
                        [children]="child.children || []"
                        [users]="child.users"></app-orgs-overview-org>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>Users</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let user of users">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        User: {{user.email}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-orgs-overview-user [user]="user"></app-orgs-overview-user>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>
