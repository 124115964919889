<h2 mat-dialog-title>{{'assign.upload-or-enter-code-dialog.title' | translate}}</h2>

<mat-dialog-content class="main-content">
    <form [formGroup]="form">
        <mat-form-field appearance="outline" style="margin-top: 12px">
            <mat-label>{{'assign.upload-or-enter-code-dialog.code-label' | translate}}</mat-label>
<!--            <input matInput class="request-code" type="text" formControlName="code">-->
            <textarea id="code-input" matInput class="request-code" placeholder="code" formControlName="code" required></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div mat-dialog-actions>
    <button id="cancel-code" mat-raised-button type="button" color="primary" (click)="onCancelClick()">{{'CANCEL' | translate}}</button>
    <input #fileUploadInput accept=".req" type="file" id="file-input" style="display: none" (change)="onUploadClick($event)" />
    <button id="upload-code" mat-raised-button type="button" color="primary" (click)="fileUploadInput.click()">{{'assign.upload-or-enter-code-dialog.upload-button' | translate}}</button>
    <button id="save-code" mat-raised-button type="button" color="primary" (click)="onSaveClick()" [disabled]="!form.valid">{{'SAVE' | translate}}</button>
</div>
