import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {IManageUser} from '../../../types/user';

export const selectUserState = (state: AppState) => state.user;

export const selectUser = () => createSelector(
  selectUserState,
  (user: IManageUser) => user
);
