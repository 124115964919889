<h2 mat-dialog-title>{{'pba-password-requirements.title' | translate}}</h2>

<mat-dialog-content>
    <ul>
        <li>{{'pba-password-requirements.length' | translate}}</li>
        <li>{{'pba-password-requirements.upper' | translate}}</li>
        <li>{{'pba-password-requirements.lower' | translate}}</li>
        <li>{{'pba-password-requirements.numeric' | translate}}</li>
        <li>{{'pba-password-requirements.special' | translate}} (<span class="fixed">~! &#64;#$^&*()_\-+=[\]:<>.]</span>)</li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="okayClicked()">{{'OK' | translate}}</button>
</mat-dialog-actions>