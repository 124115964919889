import { Injectable } from '@angular/core';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {API_URL} from '../shared/api';

export interface ILogPostResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LogService extends BaseService {
  postError(message: string): Observable<ILogPostResponse> {
    return this.postLogMessage('error', message);
  }

  postWarning(message: string): Observable<ILogPostResponse> {
    return this.postLogMessage('warning', message);
  }

  private postLogMessage(level: 'error' | 'warning', message: string): Observable<ILogPostResponse> {
    const url = this.getUrl(API_URL.API_V1_LOG);
    const data = {level, message};
    if (level == 'error') {
      console.error(message, data);
    } else if (level == 'warning') {
      console.warn(message, data);
    } else {
      console.log(message, data);
    }
    return this.http.post<ILogPostResponse>(url, data);
  }
}
