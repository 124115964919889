import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HeaderService} from '../../header.service';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map, mergeMap, startWith, switchMap, withLatestFrom, share} from 'rxjs/operators';
import {D3eService} from '../../../services/d3e-service';
import {ALL_ORGS_ID} from '../../../types/group';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IEndpoint} from '../../../types/subscription';
import {SelectionModel} from '@angular/cdk/collections';
import {IColumnChooserElement} from '../../shared/components/column-chooser/column-chooser.component';

// This function translates from the column name and direction that the sort header passes to what our API expects.
const toSorts = sort => {
  const desc = sort?.direction == 'desc';
  switch(sort?.active) {
    case "computerName":
      return [{attr: "computer_name", desc}];
    case "status":
      return [{attr: "deactivated", desc}]
    case "systemSerial":
      return [{attr: "system_serial", desc}]
    case "version":
      return [{attr: "version", desc}]
  }
  return [];
};

// Formulate the query the API expects. Ths maybe should be in the service.
const toQuery = (orgId, pageStart, pageSize, computerName, deactivated, deviceSets, sort) => ({
  selectedOrgId: orgId == ALL_ORGS_ID ? undefined : orgId,
  computer_name: computerName || undefined,
  showDeactivated: deactivated == null ? false : deactivated.toLowerCase() == 'true',
  pageSize: pageSize,
  pageStart: pageStart,
  sets: deviceSets,
  sorts: toSorts(sort),
  include_group_info: true,
});

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-hosts',
  templateUrl: './hosts.component.html',
  styleUrls: ['./hosts.component.scss']
})
export class HostsComponent {

  columns: IColumnChooserElement[] = [
    {
      title: "Selection",
      hidden: true,
      selected: true,
      value: "select",
    },
    {
      title: "Computer Name",
      selected: true,
      hidden: false,
      value: "computerName"
    },
    {
      title: "Group Name",
      selected: true,
      hidden: false,
      value: "deviceSetName"
    },
    {
      title: "Status",
      selected: true,
      hidden: false,
      value: "status",
    },
    {
      title: "System Serial",
      selected: true,
      hidden: false,
      value: "systemSerial",
    },
    {
      title: "Version",
      selected: true,
      hidden: false,
      value: "version",
    },
  ];

  public readonly PAGE_SIZE = 5;

  columnsToDisplay = this.columns.map(e => e.value);

  deviceSets$ = this.headerService.orgSelectedChanged.pipe(
    switchMap(orgId => this.d3eService.getDeviceSets(orgId == ALL_ORGS_ID ? undefined : orgId)),
    share(), // cache the result for the duration of this component instance
  );
  selectedDeviceSetId$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([])

  sort$: BehaviorSubject<any> = new BehaviorSubject({});

  computerName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  hosts$: BehaviorSubject<IEndpoint[]> = new BehaviorSubject<IEndpoint[]>([]);

  rowColorCss$ = this.deviceSets$.pipe(
    map(deviceSets => deviceSets.reduce(({ids, next}, deviceSet)=> {
      if (!ids[deviceSet.id]) {
        ids[deviceSet.id] = "row-color-" + (next % 5);  // There are 5 css classes defined.
      }
      return {ids, next: next + 1};
    }, {ids: {}, next: 1}).ids),
  );

  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  shown$ = this.hosts$.pipe(map(hosts => hosts.length));

  query$: BehaviorSubject<any> = new BehaviorSubject<any>(toQuery('All', 0 , this.PAGE_SIZE, "", "false", [], {}));

  // For checkboxes in table
  selection = new SelectionModel<IEndpoint>(true, []);

  // Button 1 disabled if number of selected items is not 1
  button1Disabled$ = this.selection.changed.pipe(
    map(() => this.selection.selected.length != 1),
    startWith(true),  // default when page loads (disabled = true)
  );

  // Button 2 disabled 0 selected or if any deactivated are selected
  button2Disabled$ = this.selection.changed.pipe(
    map(() => this.selection.selected.length == 0 || this.selection.selected.filter(e => e.deactivated == 1).length > 0),
    startWith(true),  // default when page loads (disabled = true)
  );

  // The endpoint JSON to show in the pop-out when an endpoint row is clicked.
  selectedEndpoint$: BehaviorSubject<IEndpoint> = new BehaviorSubject<IEndpoint>(null);

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly headerService: HeaderService,
              private readonly store: Store<AppState>,
              private readonly d3eService: D3eService) {

    this.headerService.setIcon("mdi-ev-plug-chademo");
    this.headerService.setTitle("Reference Page");
    this.headerService.showUserFilter(true);
    this.headerService.showRefreshButton(true);

    // triggered by sources, sets/resets the hosts$ behavior subject
    combineLatest([
      this.headerService.orgSelectedChanged,  // Selected Org ID
      this.headerService.refresh$,            // refresh button
      this.computerName$,                     // mat-select to choose computer name
      this.activatedRoute.queryParamMap,      // will allow override of deactivated on URL
      this.selectedDeviceSetId$,              // device set filter
      this.sort$,                             // changed when sort header clicked
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(([orgId, refresh, computerName, queryParamMap, deviceSets, sort]) => {
      this.hosts$.next([]);
      this.total$.next(0);
      this.selection.clear();
      this.query$.next(toQuery(orgId, 0, this.PAGE_SIZE, computerName, queryParamMap.get('deactivated'), deviceSets, sort));
    });

    // triggered by a query change, appends to the hosts$ behavior subject
    this.query$.pipe(
      mergeMap(query => this.d3eService.get_home_devices(query)),
      withLatestFrom(this.hosts$),
      untilDestroyed(this)
    ).subscribe(([r, hosts]) => {
      this.hosts$.next([...hosts, ...r.devices]);
      this.total$.next(r.total);
    });
  }

  extend() {
    const query = Object.assign({}, this.query$.getValue())
    query['pageStart'] += this.PAGE_SIZE;
    this.query$.next(query);
  }

  button1Clicked() {
    alert('button 1');
  }

  button2Clicked() {
    const text = this.selection.selected.map(e => e.computer_name).join(', ');
    alert('button 2: ' + text);
  }
}
