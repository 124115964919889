import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../header.service';
import {TranslateService} from '@ngx-translate/core';
import {D3eService} from '../../../services/d3e-service';
import {Observable} from 'rxjs';
import {ISubscription} from '../../../types/subscription';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {selectUser} from '../../store/user/user.selectors';
import {ALL_ORGS_ID} from '../../../types/group';
import { sortSubscriptionsByPriority } from '../../../shared/subscription.methods';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  subscriptions$!: Observable<ISubscription[]>;

  constructor(
    private readonly headerService: HeaderService,
    private readonly translate: TranslateService,
    private readonly d3eService: D3eService,
    private readonly store: Store<AppState>,
  ) {
    headerService.setTitle(translate.instant('subscriptions.page-title'));
    headerService.setSubtitle('');
    headerService.setIcon('mdi-file-certificate-outline');
    headerService.setNavMenuText('');
    headerService.showUserFilter(true);
    headerService.setOrgSelectorDefaultOption(translate.instant('subscriptions.default-org-selector'));
  }

  ngOnInit(): void {
    this.subscriptions$ = this.headerService.orgSelectedChanged
      .pipe(
        switchMap(organizationId => {
           return this.d3eService.get_subscriptions(organizationId, '', true).pipe(
             map(subscriptions => ({subscriptions, organizationId}))
           );
        }),
        withLatestFrom(this.store.select(selectUser())),
        map(([{subscriptions, organizationId}, user]) => {
          if (organizationId == ALL_ORGS_ID) {
            subscriptions = subscriptions.filter(subscription => {
              return subscription.group_id == user.group_id;
            });
          }
          return subscriptions.sort(sortSubscriptionsByPriority);
        })
      )
  }

}
