import { Component, Input, OnInit } from '@angular/core';
import {
    ISecurityRequestResponse,
    SecurityRequestStatus,
    SecurityRequestType
} from '../../../../types/security-requests';
import { SecurityRequestService } from '../../../../services/security-request.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification-service';
import { RestConfigService } from '../../../../services/config-service';
import { EMPTY, Observable, Subject, timer } from 'rxjs';
import { catchError, map, repeatWhen, switchMap, takeUntil } from 'rxjs/operators';
import {
    ISecurityRequestDialogResult,
    SecurityRequestDialogComponent
} from '../../encryption/security-request-dialog/security-request-dialog.component';
import AuthHelper from '../../../auth/authHelper';
import {
    SecurityRequestStatusDialogComponent
} from '../../encryption/security-request-status-dialog/security-request-status-dialog.component';
import { IDrive } from '../../../../types/drive';
import { D3eService } from '../../../../services/d3e-service';
import { IRestConfig } from '../../../../types/config';
import { IManageUser } from '../../../../types/user';
import { selectUser } from '../../../store/user/user.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { OrgType } from '../../../../types/group';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-backup-config-request',
  templateUrl: './backup-config-request.component.html',
  styleUrls: ['./backup-config-request.component.scss']
})
export class BackupConfigRequestComponent implements OnInit {
    @Input() drive: IDrive;
    @Input() restConfig: IRestConfig;

    securityRequestsEnabled = false;
    minimumApprovalPercentRequired = 0;
    currentRequest: ISecurityRequestResponse;

    user$: Observable<IManageUser> = this.store.select(selectUser());
    customerType = OrgType.CUSTOMER;

    readonly Status = SecurityRequestStatus;

    requestRefreshObesrvable$: Observable<void>;
    private readonly _stop = new Subject<void>();
    private readonly _start = new Subject<void>();

    constructor(private readonly securityRequestService: SecurityRequestService,
                private readonly dialog: MatDialog,
                private readonly translationService: TranslateService,
                private readonly notificationService: NotificationService,
                private readonly restConfigService: RestConfigService,
                private readonly d3eService: D3eService,
                private readonly store: Store<AppState>) {
    }

    ngOnInit(): void {
        this.securityRequestsEnabled = this.restConfig.security_requests_enabled;
        this.minimumApprovalPercentRequired = this.restConfig.security_request_min_percent;

        if ( this.drive.disk_config_state === 2 ) {
            this.startRequestTimer();
        }
    }

    private startRequestTimer() {
        if ( this.requestRefreshObesrvable$ != null) {
            this._start.next();
        } else {
            this.requestRefreshObesrvable$ = timer(0, 60000)
                .pipe(
                    map(() => this.getCurrentRequest()),
                    takeUntil(this._stop),
                    repeatWhen(() => this._start),
                    untilDestroyed(this)
                );
            this.requestRefreshObesrvable$.subscribe();
        }
    }

    private getCurrentRequest() {
        this.securityRequestService.getCurrentRequest(this.drive.serial).subscribe(currentRequest => {
            if (currentRequest != null) {
                this.setRequest(currentRequest);
            }
        });
    }

    private setRequest(currentRequest: ISecurityRequestResponse) {
        this.currentRequest = currentRequest;
        if ( this.currentRequest == null ||
            this.currentRequest.status === SecurityRequestStatus.DENIED ||
             this.currentRequest.status === SecurityRequestStatus.CANCELLED  ) {
            this._stop.next();
        } else if ( this.currentRequest.status === SecurityRequestStatus.ACCEPTED ) {
            const acceptedDated = Date.parse(this.currentRequest.accepted);
            const acceptedWindow = acceptedDated +
                (this.restConfig.disk_config_access_duration_minutes * 60000);
            const now = new Date().getTime();
            if ( now > acceptedWindow ) {
                this.currentRequest = null;
                this._stop.next();
            }
        }
    }

    requestBackupConfigAccess(drive: IDrive) {
        if ( ! this.securityRequestsEnabled ) {
            return;
        }

        this.securityRequestService.getApprovers().pipe(
            switchMap(response => {
                const dialogRef = this.dialog.open(
                    SecurityRequestDialogComponent,
                    {
                        width: '44em',
                        data: {
                            results: response.results,
                            minimumApprovalPercentRequired: this.minimumApprovalPercentRequired,
                            requestTitle: this.translationService.instant('ssd.disk_config.security_req_title'),
                            subTitle: this.translationService.instant( 'ssd.disk_config.security_request_subtitle',
                                {'serial': this.drive.serial, 'endpoint_name': this.drive.connected_to})

                        },
                    }
                );
                return dialogRef.afterClosed();
            }),
            switchMap((dialogResult: ISecurityRequestDialogResult) => {
                if (dialogResult) {
                    const userId = AuthHelper.getUidFromToken();
                    return this.securityRequestService.postSecurityRequest(
                      userId, SecurityRequestType.DISK_CONFIG, drive.serial, null, dialogResult.notifyApprovers);
                }
                return EMPTY;
            }),
            switchMap(() => {
                return this.securityRequestService.getCurrentRequest(this.drive.serial);
            }),
            catchError(error => {
                console.error(error);
                this.notificationService.showError(this.translationService.instant('ERROR_OCCURRED_UNKNOWN'));
                return EMPTY;
            })
        ).subscribe(currentRequest => {
            this.setRequest(currentRequest);
            this.securityRequestService.refresh$.next(null);
            this.notificationService.showSuccess(this.translationService.instant('SUCCESS'));
            this.startRequestTimer();
        });
    }

    viewBackupConfigStatus(requestId: number) {
        this.securityRequestService.getCurrentRequest(this.drive.serial).pipe(
            switchMap(currentRequest => {
                this.setRequest(currentRequest);
                return this.securityRequestService.getApproversStatus(requestId);
            }),
            switchMap(response => {
                const dialogRef = this.dialog.open(
                    SecurityRequestStatusDialogComponent,
                    {
                        width: '44em',
                        data: {
                            results: response.results,
                            minimumApprovalPercentRequired: this.minimumApprovalPercentRequired,
                            requestStatus: this.currentRequest.status,
                            dialogTitle: this.translationService.instant('ssd.disk_config.security_status_title'),
                            subTitle: this.translationService.instant( 'ssd.disk_config.security_request_subtitle',
                                {'serial': this.drive.serial, 'endpoint_name': this.drive.connected_to})
                        }
                    }
                );
                return dialogRef.afterClosed();
            }),
            switchMap((buttonClicked: boolean) => {
                if (buttonClicked) {
                    if (this.currentRequest.status === SecurityRequestStatus.APPROVED) {
                        return this.securityRequestService.putAcceptRequest(this.currentRequest.id);
                    } else if (this.currentRequest.status === SecurityRequestStatus.PENDING) {
                        return this.securityRequestService.putCancelRequest(this.currentRequest.id);
                    }
                }
                return EMPTY;
            }),
            switchMap(() => {
                return this.securityRequestService.getCurrentRequest(this.drive.serial);
            }),
            catchError(error => {
                console.log(error);
                this.notificationService.showError(this.translationService.instant('ERROR_OCCURRED_UNKNOWN'));
                return EMPTY;
            })
        ).subscribe(currentRequest => {
            this.setRequest(currentRequest);
            this.securityRequestService.refresh$.next(null);
            this.notificationService.showSuccess(this.translationService.instant('SUCCESS'));
        });
    }

    downloadDiskConfig(requestId: number) {
        this.d3eService.getDiskConfig(requestId, this.drive.serial).subscribe( response => {
            this.create_download_file(response.disk_config, response.serial);
        }, error => {
            this.notificationService.showError(this.translationService.instant('ssd.disk_config.download_failure'));
        });
    }

    create_download_file(disk_config, serial) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(disk_config));
        element.setAttribute('download', serial + '_backup.dky');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }
}
