import {IManageUser} from '../../../types/user';
import {on, createReducer} from '@ngrx/store';
import {userLoadedSuccess} from './user.actions';
import * as Sentry from '@sentry/browser';

export const initialState: IManageUser = null;

export const userReducer = createReducer(
  initialState, on(
    userLoadedSuccess,
    (state, {user}) => {
      if(!!user) {
        Sentry.setUser(user);
        Sentry.setTags({
          group_id: user.group_id,
          org_type: user.org_type?.name,
          role: user.role,
          ip_address: user.ip_address,
        });
      }
      return user;
    }
  )
);
