import {ErrorHandler, Injectable} from '@angular/core';
import {ConfigurationService} from '../services/configuration-service';
import * as Sentry from '@sentry/browser';
import {from} from 'rxjs';

@Injectable()
export class CtmcErrorHandler implements ErrorHandler {
  constructor(private readonly configurationService: ConfigurationService) {
    from(configurationService.load()).subscribe(config => {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.sentryEnvironment.toLowerCase(),
      });
    });
  }

  handleError(error: any) {
    Sentry.captureException(error);
  }
}
