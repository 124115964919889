import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { WINDOW } from '../app/window.providers';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(@Inject(WINDOW) private window: any,
              private readonly translate: TranslateService,
              private readonly snackBar: MatSnackBar) { }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  copyMessage(copyText: string, snackBarMessage: string = '', duration=2000): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.window.navigator.clipboard != null) {
        this.window.navigator.clipboard.writeText(copyText)
          .then(() => {
            if (snackBarMessage) {
              this.snackBarMessage(snackBarMessage, duration);
              return resolve(true);
            }
          })
          .catch(error => {
            this.snackBarMessage(error, 0);
            return resolve(false);
        });
      } else {
        this.snackBarMessage(this.translate.instant('ERROR_INSECURE_ENVIRONMENT'), 0);
        return resolve(false);
      }
    });
  }

  private snackBarMessage(snackBarMessage: string, duration=2000) {
    this.snackBar.open(snackBarMessage, this.translate.instant('COPY_DISMISS'), {
      duration: duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['copy-text-snackbar']
    });
  }
}
