import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardGridService {
  private gridResize$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  get gridResize() {
    return this.gridResize$;
  }
}
