
<div class="container">
    <ng-container *ngIf="subscriptions$ | async as subscriptions">
        <div class="subscription-wrapper" *ngFor="let subscription of subscriptions">
            <app-subscription [subscription]="subscription"></app-subscription>
        </div>
        <ng-container *ngIf="!subscriptions?.length">
            <h1>{{'subscriptions.no-subscriptions' | translate}}</h1>
        </ng-container>
    </ng-container>
</div>
