
<div *ngIf="results$ | async as results">
    <mat-list *ngIf="results.activations.length > 0">
        <div mat-subheader>{{'recent-search.activations' | translate}}</div>
        <mat-list-item *ngFor="let el of results.activations">
            <i class="mdi mdi-monitor"></i>
            <a [routerLink]="['/device', el.id]">{{el.name}}</a>
        </mat-list-item>
    </mat-list>

    <mat-list *ngIf="results.users.length > 0">
        <div mat-subheader>{{'recent-search.users' | translate}}</div>
        <mat-list-item *ngFor="let el of results.users">
            <i class="mdi mdi-account"></i>
            <a [routerLink]="['/users']" [queryParams]="{'search': el.name}">{{el.name}}</a>
        </mat-list-item>
    </mat-list>
</div>
